import posthog from 'posthog-js'
import { IS_PROD } from '~/constants'
import { EVENTS_NAME } from '~/types'
import { delayedCallback } from '~/utils/common'

/**
 * Initialize SDK
 */
const init = (key: string) => {
    posthog.init(key, {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
        opt_in_site_apps: true,
        debug: !IS_PROD
    })
}

/**
 * Identify user
 * @param userId user id
 * @param props profile properties
 * @param propsSetOnce profile properties but only set once. will be ignored if it has been defined
 */
const identify = (userId: string, props?: Record<string, any>, propsSetOnce?: Record<string, any>) => {
    posthog.identify(userId, props, propsSetOnce)
}

/**
 * Send Posthog event
 * @param name event name
 * @param props event properties
 * @param userProps user proerties
 * @param userPropsSetOnce user properties but only set once. will be ignored if it has been defined
 * @param callback callback
 */
const capture = (
    name: EVENTS_NAME,
    props?: Record<string, any>,
    userProps?: Record<string, any>,
    userPropsSetOnce?: Record<string, any>,
    callback = () => false
) => {
    const properties: Record<string, any> = { ...props, platform: 'web' }

    if (userProps) {
        properties.$set = userProps
    }

    if (userPropsSetOnce) {
        properties.$setOnce = userPropsSetOnce
    }

    posthog.capture(name, properties)
    delayedCallback(callback)
}

/**
 * Reset user
 */
const reset = () => {
    posthog.reset()
}

const onFeatureFlags = (callback: () => void) => {
    posthog.onFeatureFlags(callback)
}

const isFeatureEnabled = (id: string) => posthog.isFeatureEnabled(id)

const $posthog = {
    init,
    identify,
    capture,
    reset,
    onFeatureFlags,
    isFeatureEnabled
}

export default $posthog
