import { $error } from '~/utils/log'

const setVisitor = (name: string, phone: string, userId: string) => {
    try {
        window.$zoho?.salesiq?.visitor?.name(name)
        window.$zoho?.salesiq?.visitor?.contactnumber(phone)
        window.$zoho?.salesiq?.visitor?.id(userId)
    } catch (e) {
        $error(e)
    }
}

const resetVisitor = () => {
    try {
        window.$zoho?.salesiq?.reset()
    } catch (e) {
        $error(e)
    }
}

const openChat = () => {
    try {
        window.$zoho?.salesiq?.floatwindow?.visible('show')
    } catch (e) {
        $error(e)
    }
}

const $zohoSalesIq = {
    setVisitor,
    resetVisitor,
    openChat
}

export default $zohoSalesIq
