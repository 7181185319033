import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import locale from '~/locale'

import {
    BulkUploadProductImageResponse,
    BulkUploadUpdateData,
    BulkUploadUpdateDataResponse,
    DataResponse,
    ErrorField,
    GetBulkUploadPaginatedData,
    GetBulkUploadPaginatedDataItem,
    GetBulkUploadPaginatedParams,
    GetBulkUploadPaginatedResponse
} from '~/types'
import { $error, $log } from '~/utils/log'

export const BulkUploadPaginatedItemDefault: GetBulkUploadPaginatedDataItem = {
    categoryName: '',
    createdAt: 0,
    discountedPrice: '',
    errors: {},
    failed: false,
    price: '',
    productDescription: '',
    productImages: [],
    productImageUploading: false,
    productName: '',
    quantity: '',
    recordId: '',
    rowId: 0,
    storeId: '',
    unit: '',
    updatedAt: 0,
    uploadId: '',
    weight: ''
}

export const BulkUploadPaginatedDefault: GetBulkUploadPaginatedData = {
    uploadId: '',
    totalRecords: 0,
    failed: false,
    failedPages: [],
    records: []
}

export const GetBulkUploadPaginatedParamsDefault: GetBulkUploadPaginatedParams = {
    limit: 10,
    offset: 0,
    storeId: ''
}

/**
 * Upload and validate bulk upload file
 * @param file
 * @param storeId
 * @returns
 */
export const ValidateBulkUpload = async (file: File, storeId: string): Promise<DataResponse<boolean>> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('storeId', storeId)

    const request: DataResponse<boolean> = await $axios
        .post(`${API_BASE_URL}/uploads/products`, formData, {
            headers: {
                'Content-Type': ' multipart/form-data'
            }
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                data: false,
                error: {
                    field: '',
                    message: locale.id.product.bulk_upload.error.document_exist
                }
            }
        })
        .catch(e => {
            $error(e)

            const error: ErrorField = {
                field: '',
                message: 'Upload file gagal'
            }

            if (e.response) {
                $log(e.response.data)
                if (e.response.data.message === 'Upload is already in progress') {
                    error.field = 'exist'
                    error.message = locale.id.product.bulk_upload.error.document_exist
                }
            }

            return {
                data: false,
                error
            }
        })

    return request
}

/**
 * Get bulk upload paginated data
 * @param params
 * @returns
 */
export const GetBulkUploadPaginated = async (params: GetBulkUploadPaginatedParams): Promise<GetBulkUploadPaginatedResponse> => {
    const request: GetBulkUploadPaginatedResponse = await $axios
        .get<GetBulkUploadPaginatedData>(`${API_BASE_URL}/uploads/products`, {
            params
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                data: { ...BulkUploadPaginatedDefault },
                error: {
                    field: '',
                    message: 'Gagal memuat dokumen'
                }
            }
        })
        .catch(e => {
            $error(e)
            const error: ErrorField = {
                field: '',
                message: 'Gagal memuat dokumen'
            }

            if (e.response) {
                if (e.response.data.status === 404) {
                    error.field = 'not-found'
                    error.message = 'Belum ada dokumen untuk di tinjau'
                }
            }

            return {
                data: { ...BulkUploadPaginatedDefault },
                error
            }
        })

    return request
}

/**
 * Submit bulk upload data
 * @param storeId
 * @returns
 */
export const SubmitBulkUploadData = async (storeId: string): Promise<DataResponse<boolean>> => {
    const request: DataResponse<boolean> = await $axios
        .post(`${API_BASE_URL}/uploads/products/submit`, null, {
            params: {
                storeId
            }
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }

            return {
                data: false,
                error: {
                    field: '',
                    message: 'Gagal menambah produk. Silahkan coba lagi!'
                }
            }
        })
        .catch(e => {
            $error(e)

            const error: ErrorField = {
                field: '',
                message: 'Gagal menambah produk. Silahkan coba lagi!'
            }

            if (e.response) {
                $log(e.response.data)
                if (e.response.data.message === 'Upload has validation errors') {
                    error.field = 'invalid'
                    error.message = 'Harap melengkapi data yang di from yang berwarna merah'
                }
            }

            return {
                data: false,
                error
            }
        })

    return request
}

/**
 * Delete bulk upload data
 * @param storeId
 * @returns
 */
export const DeleteBulkUploadData = async (storeId: string): Promise<DataResponse<boolean>> => {
    const request: DataResponse<boolean> = await $axios
        .delete(`${API_BASE_URL}/uploads/products`, {
            params: {
                storeId
            }
        })
        .then(res => {
            if (res.status === 204) {
                return {
                    data: true
                }
            }

            return {
                data: false,
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: false,
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    return request
}

/**
 * Upload bulk upload product image
 * @param image
 * @param storeId
 * @param recordId
 * @returns
 */
export const BulkUploadProductImage = async (image: File, storeId: string, recordId: string): Promise<BulkUploadProductImageResponse> => {
    const formData = new FormData()
    formData.append('images', image)

    const request: BulkUploadProductImageResponse = await $axios
        .post<GetBulkUploadPaginatedDataItem>(`${API_BASE_URL}/uploads/products/image`, formData, {
            params: {
                storeId,
                recordId
            },
            headers: {
                'Content-Type': ' multipart/form-data'
            }
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                data: { ...BulkUploadPaginatedItemDefault },
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)

            return {
                data: { ...BulkUploadPaginatedItemDefault },
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    return request
}

/**
 * Update bulk upload data
 * @param records
 * @param storeId
 * @param limit
 * @returns
 */
export const UpdateBulkUploadData = async (
    records: BulkUploadUpdateData[],
    storeId: string,
    limit: number
): Promise<BulkUploadUpdateDataResponse> => {
    const request: BulkUploadUpdateDataResponse = await $axios
        .put<GetBulkUploadPaginatedDataItem>(`${API_BASE_URL}/uploads/products/`, records, {
            params: {
                storeId,
                limit
            }
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }

            return {
                data: { ...BulkUploadPaginatedItemDefault },
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)

            return {
                data: { ...BulkUploadPaginatedItemDefault },
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    return request
}

export const MAX_BULK_UPLOAD_FILE_SIZE = 5000000
