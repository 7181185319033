import IconTokoko from '../Icons/IconTokoko'
import Meta from '../Meta'

const InitialPageLoad = () => {
    return (
        <>
            <Meta title="Tokoko.id"></Meta>
            <div
                className="fixed left-0 top-0 flex h-screen w-full items-center justify-center transition-all"
                data-testid="initial-page-load">
                <div className="relative h-36 w-36">
                    <IconTokoko></IconTokoko>
                </div>
            </div>
        </>
    )
}

export default InitialPageLoad
