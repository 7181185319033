import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { APIResponse, UploadOnboardingImageData, UploadOnboardingImageProps, UploadOnboardingImageResponse } from '~/types'
import { getErrorMessage } from '~/utils/api'

export const UploadOnboardingImageDefaultData: UploadOnboardingImageData = {
    name: '',
    type: 'SIGNATURE',
    url: ''
}

export const UploadOnboardingImage = async ({
    file,
    type,
    onboardingId
}: UploadOnboardingImageProps): Promise<UploadOnboardingImageResponse> => {
    const formData = new FormData()
    formData.append('file', file)
    const request: UploadOnboardingImageResponse = await $axios
        .put<UploadOnboardingImageData & APIResponse>(`${API_BASE_URL}/onboarding/image/${onboardingId}/${type}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: { ...UploadOnboardingImageDefaultData },
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<UploadOnboardingImageData>(UploadOnboardingImageDefaultData, e)
        })

    return request
}
