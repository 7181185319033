import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { APIResponse, GetOnboardingDetailsResponse, Onboarding } from '~/types'
import { getErrorMessage } from '~/utils/api'

/**
 * Get onboarding details
 * @param storeId
 * @returns Onboarding
 */
export const GetOnboardingDetails = async (storeId: string): Promise<GetOnboardingDetailsResponse> => {
    const request: GetOnboardingDetailsResponse = await $axios
        .get<Onboarding & APIResponse>(API_BASE_URL + '/onboarding/stores/' + storeId)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<Onboarding>({}, e)
        })

    return request
}
