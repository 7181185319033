import { useRouter } from 'next/router'
import queryString from 'query-string'
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { GlobalData, useGlobal } from '~/components/Global/GlobalProvider'
import { STORAGE_WEBVIEW } from '~/constants'
import { StoreDetailDefaultValue } from '~/data/store'
import { requestInterceptor, responseInterceptor } from '~/lib/axiosInterceptor'
import $posthog from '~/lib/posthog'
import $zohoSalesIq from '~/lib/zoho'
import locale from '~/locale'
import { StoreDetail } from '~/types/store'
import { initAnalytics } from '~/utils/amplitude'
import { authenticate } from '~/utils/auth'
import { closeWebView, delayedCallback } from '~/utils/common'
import LocalStore, { LocalStoreKey } from '~/utils/local-store'
import $sentry from '~/utils/sentry'

interface AuthProps {
    appXsrfToken: string | undefined
    initialData: GlobalData
    pageProps: Record<string, unknown>
    setStore: Dispatch<SetStateAction<StoreDetail>>
    setInitialData: Dispatch<SetStateAction<GlobalData>>
    children?: JSX.Element
}

interface ProviderProps {
    showOnlineStore: boolean
    localStore?: LocalStore<LocalStoreKey>
}

export const AuthContextProvider = createContext<ProviderProps>({
    showOnlineStore: true
})

export const useAuth = () => useContext(AuthContextProvider)

const AuthProvider: FC<AuthProps> = ({ appXsrfToken, initialData, pageProps, setStore, setInitialData, children }) => {
    const router = useRouter()
    const { snackbar, onboardCheck, localStore, setLocalStore, setLoadPage } = useGlobal()
    const [showOnlineStore, setShowOnlineStore] = useState(true)
    const [xsrfToken, setXsrfToken] = useState(appXsrfToken)

    useEffect(() => {
        requestInterceptor(xsrfToken)
        responseInterceptor(snackbar.snackbarHandle, setXsrfToken)
    }, [router.pathname, pageProps, xsrfToken])

    useEffect(() => {
        if (router.isReady) {
            init()
            $posthog.init(initialData.ENV.POSTHOG_API_KEY)
        }
    }, [router.isReady])

    const init = async () => {
        const { platform } = queryString.parse(window.location.search)
        const _isAndroidWebview = platform === 'android'
        localStorage.setItem(STORAGE_WEBVIEW, String(_isAndroidWebview))
        load()
        initAnalytics(initialData.ENV.SELLER_AMP_KEY, initialData.ENV.BW_AMP_KEY)
    }

    const load = async () => {
        const data = await authenticate(router)

        if (data.store) {
            setLocalStore(new LocalStore<LocalStoreKey>(1, data.store.storeId))

            setStore({
                ...StoreDetailDefaultValue,
                ...data.store
            })

            localStorage.setItem('storeId', data.store.storeId)

            $posthog.identify(data.store.userId, {
                name: data.store.storeName,
                phone: data.store.phone
            })

            $posthog.onFeatureFlags(() => {
                if ($posthog.isFeatureEnabled('hide-online-store')) {
                    setShowOnlineStore(false)
                }
            })

            $zohoSalesIq.setVisitor(data.store.storeName, data.store.phone, data.store.userId)

            await onboardCheck(data.store)
        }

        setInitialData(state => ({ ...state, ...data }))
        $sentry.setUser()

        const { platform } = queryString.parse(window.location.search)

        if (data.redirect) {
            if (platform !== 'android') {
                router.push(data.redirect).then(() => {
                    setLoadPage(false)
                })
                return
            }

            snackbar.snackbarHandle({
                value: true,
                message: locale.id.common.session_timeout,
                style: 'error',
                noTimeOut: true
            })

            delayedCallback(() => closeWebView(), 2500)
        }

        setLoadPage(false)
    }

    const auth = { showOnlineStore, localStore }

    return <AuthContextProvider.Provider value={auth}>{children}</AuthContextProvider.Provider>
}
export default AuthProvider
