import { useGetDeviceWidth } from '@bukuwarung/sachet'
import '@bukuwarung/sachet/styles/utilities-only.css'
import App, { AppContext, AppInitialProps, AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useState } from 'react'
import 'tippy.js/dist/tippy.css'

import { StoreDetailDefaultValue } from '~/data/store'
import { StoreDetail } from '~/types/store'

import AuthProvider from '~/components/Auth/AuthProvider'
import GlobalProvider, { AppCustomProps, GlobalData, getCSP } from '~/components/Global/GlobalProvider'
import Snackbar, { SnackbarDefaultValue } from '~/components/Layout/Dashboard/Snackbar'
import Popup, { PopupDefaultValue } from '~/components/Popup/Popup'

import Cookies from 'cookies'
import { randomBytes } from 'crypto'
import Script from 'next/script'
import { LayoutWrapper } from '~/components/Layout/LayoutWrapper'
import { EnvironmentVariablesDefault } from '~/types/env'
import '../styles/destyle.css'
import '../styles/fonts.css'
import '../styles/globals.scss'

const MyApp = ({ Component, pageProps, ENV, appNonce, appXsrfToken }: AppProps & AppCustomProps) => {
    const router = useRouter()
    const [loadPage, setLoadPage] = useState(true)
    const [store, setStore] = useState<StoreDetail>({
        ...StoreDetailDefaultValue
    })

    const [initialData, setInitialData] = useState<GlobalData>({
        popup: { ...PopupDefaultValue },
        snackbar: { ...SnackbarDefaultValue },
        ENV: ENV ? ENV : EnvironmentVariablesDefault,
        appNonce: appNonce || '',
        onboardCheck: async () => void null,
        setLocalStore: () => false,
        setShowOnlineStore: () => false,
        setLoadPage: () => false,

        setStore
    })
    const [deviceWidth, setDeviceWidth] = useState(0)
    const showSnackbar = true

    useGetDeviceWidth(setDeviceWidth)

    return (
        <>
            <Script
                type="text/javascript"
                id="zsiqchat"
                nonce={
                    initialData.appNonce
                }>{`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siqfb6393b1457724ff4d5ef3f1a6d6002041b047592b5ef40111e82721f5147d51", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";s.nonce="${initialData.appNonce}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`}</Script>
            <GlobalProvider initialData={initialData} deviceWidth={deviceWidth} store={store} setLoadPage={setLoadPage} loadPage={loadPage}>
                <AuthProvider
                    appXsrfToken={appXsrfToken}
                    pageProps={pageProps}
                    setStore={setStore}
                    setInitialData={setInitialData}
                    initialData={initialData}>
                    <>
                        <div id="main-wrapper" className="relative h-full w-full overflow-hidden">
                            <LayoutWrapper deviceWidth={deviceWidth} loadPage={loadPage} pathname={router.pathname} query={router.query}>
                                <Component {...pageProps} />
                            </LayoutWrapper>
                            {showSnackbar && <Snackbar></Snackbar>}
                            <Popup></Popup>
                        </div>
                        <script
                            src={`https://maps.googleapis.com/maps/api/js?key=${initialData.ENV.GOOGLE_MAPS_KEY}&sensor=false&callback=Function.prototype`}
                        />
                    </>
                </AuthProvider>
            </GlobalProvider>
        </>
    )
}

MyApp.getInitialProps = async (context: AppContext): Promise<AppCustomProps & AppInitialProps> => {
    const initalProps = await App.getInitialProps(context)
    const { req, res } = context.ctx

    if (!!res) {
        const nonce = randomBytes(8).toString('base64')
        res.setHeader('Content-Security-Policy', getCSP(nonce))
        // to read in document
        res.setHeader('App-Nonce', nonce)

        const cookies = new Cookies(req!, res)

        return {
            ...initalProps,
            ENV: {
                BUYER_AMP_KEY: process.env.BUYER_AMP_KEY || '',
                BUYER_BASE_URL: process.env.NEXT_PUBLIC_BUYER_BASE_URL || '',
                BW_AMP_KEY: process.env.BW_AMP_KEY || '',
                GOOGLE_MAPS_KEY: process.env.GOOGLE_MAPS_KEY || '',
                SELLER_AMP_KEY: process.env.SELLER_AMP_KEY || '',
                SELLER_BASE_URL: process.env.NEXT_PUBLIC_SELLER_BASE_URL || '',
                POSTHOG_API_KEY: process.env.POSTHOG_API_KEY || ''
            },
            appNonce: nonce, // to read in component
            appXsrfToken: cookies.get('xsrf_token')
        }
    }

    return {
        ...initalProps
    }
}

export default MyApp
