import locale from '~/locale'

export const PaymentTypeTranslator = (paymentType: 'COD' | 'BNPL' | 'PAYMENT' | 'TOP' | null) => {
    switch (paymentType) {
        case 'COD':
            return 'Bayar Tunai'
        case 'BNPL':
            return 'Talangin Dulu'
        case 'PAYMENT':
            return 'Pembayaran Tokoko'
        case 'TOP':
            return locale.id.common.payment_method.top
        default:
            return paymentType
    }
}
