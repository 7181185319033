import { COLORS } from '@bukuwarung/sachet'

const IconTokoko = () => {
    return (
        <svg className="h-full w-full" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70 8C86.7263 8 100.286 21.0628 100.286 37.1765H123V104.444C123 119.663 110.345 132 94.7333 132H45.2667C29.6554 132 17 119.663 17 104.444V37.1765H39.7143C39.7143 21.0628 53.2737 8 70 8ZM73.7828 59.6693H43.4971V74.4312H58.6399V111.336H73.7828V59.6693ZM88.9274 59.6693C84.7458 59.6693 81.356 62.9738 81.356 67.0502C81.356 71.1266 84.7458 74.4312 88.9274 74.4312C93.109 74.4312 96.4988 71.1266 96.4988 67.0502C96.4988 62.9738 93.109 59.6693 88.9274 59.6693ZM69.9999 15.2918C57.9032 15.2918 48.0151 24.4016 47.3242 35.8884L47.2856 37.1742H92.7142C92.7142 25.5205 83.2581 15.9945 71.3346 15.329L69.9999 15.2918Z"
                fill={COLORS.MAIN.PRIMARY[600]}
            />
        </svg>
    )
}

export default IconTokoko
