import { Icon, Spacer } from '@bukuwarung/sachet'
import { useGlobal } from '~/components/Global/GlobalProvider'

interface OnboardingPromptProps {
    onboardProgress: number
    setShowOnboard: (value: boolean) => void
    setShowOnboardPrompt: (value: boolean) => void
}

export const OnboardingPrompt = ({ onboardProgress, setShowOnboard, setShowOnboardPrompt }: OnboardingPromptProps) => {
    const { localStore } = useGlobal()

    return (
        <div className="bg-green-50 px-3 py-4">
            <div className="mb-4 flex items-start text-green-400" role="button" onClick={() => setShowOnboard(true)}>
                <h3 className="mr-2 text-sm font-bold">Yuk, buat transaksi pertama Anda!</h3>
                <Spacer></Spacer>
                <div className="flex flex-shrink-0 items-center justify-center">
                    {onboardProgress === 4 ? (
                        <div className="flex items-center justify-center" onClick={e => e.stopPropagation()}>
                            <Icon
                                name="x"
                                size="lg"
                                onClick={() => {
                                    setShowOnboardPrompt(false)
                                    localStore?.set('ONBOARDING_CLOSED', true)
                                }}></Icon>
                        </div>
                    ) : (
                        <Icon name="chevron-right" size="lg"></Icon>
                    )}
                </div>
            </div>
            <div className="rounded-lg bg-neutrals-light px-3 pb-3 pt-2 shadow">
                <div className="mb-1.5 flex items-center text-sm">
                    <p className="mr-2.5  font-bold text-neutrals-900">Lanjut Buat Invoice dan Pembayaran</p>
                    <p className="flex-shrink-0 text-neutrals-400">
                        <b className="text-green-400">{onboardProgress}</b>/4 Selesai
                    </p>
                </div>
                <div className="flex h-3 items-stretch rounded-3xl bg-neutrals-200 bg-opacity-70 p-0.5">
                    <div style={{ width: `${(onboardProgress / 4) * 100}%` }} className={`rounded-lg bg-green-400`}></div>
                </div>
            </div>
        </div>
    )
}
