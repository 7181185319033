import Head from 'next/head'

interface MetaProps {
    title?: string
    description?: string
    type?: 'default' | 'auth'
    children?: JSX.Element | JSX.Element[]
}

const Meta = ({ title, description, type, children }: MetaProps) => {
    return (
        <>
            <Head>
                <title>{title || 'Tokoko.id'}</title>
                <meta name="description" content={description || ''}></meta>
                {type === 'default' && <meta name="robot" content="noindex, nofollow"></meta>}
                {children}
            </Head>
        </>
    )
}

export default Meta
