import amplitude from 'amplitude-js'
import { DIGITAL_PRODUCT_FLAG_STORAGE_NAME, INVENTORY_FLAG_STORAGE_NAME, TOKOKO_SELLER_WEB, TOKOKO_SELLER_WEB_VERSION } from '~/constants'
import { $log } from '~/utils/log'
import { getUserId } from './auth'

export const initAnalytics = (sellerAmpKey: string, bwAmpKey: string) => {
    initAmplitude(sellerAmpKey, bwAmpKey)
}

const initAmplitude = (sellerAmpKey: string, bwAmpKey: string) => {
    amplitude.getInstance('seller').init(sellerAmpKey)
    amplitude.getInstance('bw').init(bwAmpKey)
}

export type EventNames =
    | 'registration_input'
    | 'user_login:otp_input_box_shown'
    | 'store_onboarding:create_store_button_clicked'
    | 'user_login:otp_verify_button_clicked'
    | 'homescreen:tab_bar_home_clicked'
    | 'homescreen:tab_bar_orders_clicked'
    | 'homescreen:tab_bar_invoice_clicked'
    | 'homescreen:tab_bar_products_clicked'
    | 'homescreen:tab_bar_customers_clicked'
    | 'homescreen:tab_bar_account_clicked'
    | 'go_to_add_customer'
    | 'order_tap_contact_buyer'
    | 'add_product_tap_next'
    | 'edit_product_tap_next'
    | 'save_product_category'
    | 'edit_product_category_tap_save'
    | 'active_order_tap_detail'
    | 'homescreen_account:log_out_button_clicked'
    | 'order_tap_cancel'
    | 'cancel_order_confirmation_ok'
    | 'cancel_order_confirmation_cancel'
    | 'order_status_tap_sent'
    | 'sent_order_confirmation_ok'
    | 'sent_order_confirmation_cancel'
    | 'order_status_tap_completed'
    | 'order_tracking_remind_customer'
    | 'complete_order_confirmation_ok'
    | 'complete_order_confirmation_cancel'
    | 'order_status_tap_ready_pickup'
    | 'homescreen_product:add_product_floating_button_clicked'
    | 'delete_product_confirmation_ok'
    | 'product_category_delete_category'
    | 'homescreen_account:profile_settings_button_clicked'
    | 'edit_account_tap_save'
    | 'home_tap_share'
    | 'seller_home_tap_check_old_analytics'
    | 'homescreen:analytics_card_clicked'
    | 'seller_home_tap_all_product'
    | 'seller_analytics_traffic_tap_filter_date'
    | 'seller_analytics_accounting_tap_filter_date'
    | 'seller_analytics_accounting_tap_comparison'
    | 'order_status_remind_buyer'
    | 'import_product_add'
    | 'import_product_tap_download_sample'
    | 'import_product_put_file'
    | 'import_product_tap_upload_status'
    | 'import_product_tap_add_image'
    | 'import_product_review_back'
    | 'import_product_review_save'
    | 'import_product_review_remove'
    | 'seller_analytics_menu_tap_accounting'
    | 'seller_analytics_menu_tap_tarffic'
    | 'supplier_bnpl_onboarding:form_1_saved'
    | 'supplier_bnpl_onboarding:form_2_saved'
    | 'supplier_bnpl_onboarding:form_3_saved'
    | 'supplier_bnpl_onboarding:upload_photo_button_clicked'
    | 'supplier_bnpl_onboarding:form_4_photo_confirmed'
    | 'delivery_address_tap_save'
    | 'delivery_address_map_tap_save'
    | 'delivery_tap_address'
    | 'delivery_courier_tap_save'
    | 'homescreen_customers:show_all_card_clicked'
    | 'customers_list_filter_all'
    | 'homescreen_customers:customer_details_button_clicked'
    | 'customers_list_bnpl_ajukan'
    | 'customers_list_sort_alphabet'
    | 'customers_list_sort_terbaru'
    | 'customers_list_sort_terlama'
    | 'homescreen_customers:add_customer_button_clicked'
    | 'customers_add_customers_save'
    | 'customers_add_customers_add'
    | 'customers_add_customers_back'
    | 'customers_detail_name_edit'
    | 'customers_detail_contact_wa'
    | 'customers_detail_address_edit'
    | 'customers_detail_order_filter_semua'
    | 'customers_detail_order_filter_pesanan_baru'
    | 'customers_detail_order_filter_dikirim'
    | 'customers_detail_order_filter_selesai'
    | 'customers_detail_order_filter_batal'
    | 'customers_detail_bnpl_ajukan'
    | 'customers_bnpl_ajukan_confirm'
    | 'customers_bnpl_ajukan_cancel'
    | 'customers_detail_name_edit_confirm'
    | 'customers_detail_name_edit_cancel'
    | 'customers_detail_address_edit_save'
    | 'customers_detail_address_edit_back'
    | 'homescreen_account:inventory_toggle_switch_clicked'
    | 'inventory_onboard_initialstock_save'
    | 'inventory_onboard_digitalproduct_continue'
    | 'invoice_tap_pagination'
    | 'add_product_sku_input'
    | 'homescreen_product:products_search_box_clicked'
    | 'product_tap_pagination'
    | 'homescreen_product:product_details_shown'
    | 'product_tap_edit_stock'
    | 'product_tap_edit_stock_save'
    | 'product_tap_toggle_stock'
    | 'wholesale_price_tap_save'
    | 'edit_product_tap_wholesale_price'
    | 'invoice_management:landing_page_viewed'
    | 'invoice_management:banner_clicked'
    | 'invoice_management:card_clicked'
    | 'invoice_management:create_button_clicked'
    | 'invoice_management:edit_button_clicked'
    | 'invoice_details:landing_page_viewed'
    | 'invoice_details:save_button_clicked'
    | 'invoice_details:submit_button_clicked'
    | 'invoice_details:paid_button_clicked'
    | 'invoice_details:cancel_invoice_button_clicked'
    | 'invoice_details:add_customer_button_clicked'
    | 'add_customer:landing_page_viewed'
    | 'add_customer:submit_button_clicked'

export const sendAmplitudeEvent = (name: EventNames, props?: Record<string, unknown>, callback?: () => void) => {
    if (typeof window !== 'undefined') {
        const amplInstance = amplitude.getInstance('seller')
        const userId = getUserId()
        const inventoryStatus = getInventoryStatus()
        const haveDigitalProduct = getDigitalProductAvailability()
        const storeId = getStoreId()

        const userProperties = {
            sellerUserId: userId,
            sellerStoreId: storeId,
            inventory_status: inventoryStatus,
            is_having_digital_product: haveDigitalProduct,
            web_source: TOKOKO_SELLER_WEB,
            web_version: TOKOKO_SELLER_WEB_VERSION
        }

        amplInstance.setUserId(userId)
        amplInstance.setUserProperties(userProperties)
        amplInstance.logEvent(name, { ...props, platform: 'website' })

        $log('send amplitude event', name, props)

        if (callback) {
            setTimeout(() => {
                callback()
            }, 500)
        }
    }
}

const getStoreId = () => {
    return localStorage.getItem('storeId') || null
}

const getInventoryStatus = () => {
    const isActive = localStorage.getItem(INVENTORY_FLAG_STORAGE_NAME)

    if (isActive === '' || !isActive) {
        return 'inactive'
    }

    return isActive
}

const getDigitalProductAvailability = () => {
    const haveDigitalProduct = localStorage.getItem(DIGITAL_PRODUCT_FLAG_STORAGE_NAME)

    if (haveDigitalProduct === '' || !haveDigitalProduct) {
        return null
    }

    return haveDigitalProduct
}

export const sendAmplitudeEventBW = (name: EventNames, props?: Record<string, unknown>, userId?: string, callback?: () => void) => {
    if (typeof window !== 'undefined') {
        const amplInstance = amplitude.getInstance('bw')

        const userProperties = {
            web_source: TOKOKO_SELLER_WEB,
            web_version: TOKOKO_SELLER_WEB_VERSION
        }

        amplInstance.setUserId(userId || '')
        amplInstance.setUserProperties(userProperties)
        amplInstance.logEvent(name, { ...props, platform: 'website' })

        $log('send amplitude event', name, props)

        if (callback) {
            setTimeout(() => {
                callback()
            }, 500)
        }
    }
}
