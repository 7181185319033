import $axios from '~/lib/axios'

import { API_BASE_URL, DIGITAL_PRODUCT_FLAG_STORAGE_NAME } from '~/constants'
import {
    APIResponse,
    ConvertDigitalToPhysicalMethodResponse,
    GetProductsFilterApiParams,
    GetProductsFilterParams,
    GetProductsParams,
    GetProductsResponse,
    GetSearchProductsParams,
    Product,
    ProductDataResponse,
    ProductField,
    ProductFieldError,
    ProductWholesale,
    SearchProductsItems,
    TotalProductCountResponse
} from '~/types'
import { getErrorMessage } from '~/utils/api'
import { $error } from '~/utils/log'

export const ProductFieldDefaultValue: ProductField = {
    available: true,
    category: {
        categoryId: '',
        categoryLogo: '',
        categoryName: '',
        createdAt: 0,
        createdByDevice: '',
        createdByUser: '',
        deleted: false,
        storeId: '',
        updatedAt: 0,
        updatedByDevice: '',
        updatedByUser: ''
    },
    price: 0,
    priceDiscounted: 0,
    productDescription: '',
    productId: '',
    productImage: [],
    productName: '',
    quantity: 0,
    storeId: '',
    type: 'PHYSICAL',
    unit: 'Pcs',
    variant: '',
    weight: 0,
    weightUnit: 'gram',
    sku: null,
    wholesalePrices: null
}

export const wholesaleDefaultValue: ProductWholesale = {
    discountType: 'FLAT',
    isAutomaticFillUpAllowed: false,
    enable: true,
    prices: []
}

export const ProductFieldErrorDefaultValue: ProductFieldError = {
    available: '',
    category: '',
    price: '',
    priceDiscounted: '',
    productDescription: '',
    productId: '',
    productImage: '',
    productName: '',
    quantity: '',
    storeId: '',
    type: '',
    unit: '',
    variant: '',
    weight: '',
    weightUnit: '',
    stock: '',
    sku: '',
    wholesalePrices: ''
}

export const ProductDefaultData: Product = {
    ...ProductFieldDefaultValue,
    catalogueProduct: false,
    createdAt: 0,
    createdByDevice: '',
    createdByUser: '',
    deleted: false,
    ordering: {
        webDefault: ''
    },
    isAvailable: false,
    productViews: 0,
    updatedAt: 0,
    updatedByDevice: '',
    updatedByUser: ''
}

export const UnitOptions = [
    {
        value: 'default_gram',
        text: 'Gram'
    },
    {
        value: 'default_kg',
        text: 'Kg'
    },
    {
        value: 'default_lusin',
        text: 'Lusin'
    },
    {
        value: 'default_pcs',
        text: 'Pcs'
    }
]

export const GetProducts = async ({ limit, offset, storeId }: GetProductsParams): Promise<GetProductsResponse> => {
    const request: GetProductsResponse = await $axios
        .get<ProductDataResponse & APIResponse>(API_BASE_URL + '/products/' + storeId + '', {
            params: {
                limit,
                offset
            }
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data.result
                }
            }

            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    return request
}

export const GetProductsWithFilter = async ({
    page,
    offset,
    sortDirection,
    sortBy,
    storeId,
    type = ''
}: GetProductsFilterParams): Promise<GetProductsResponse & TotalProductCountResponse> => {
    const pathParams: GetProductsFilterApiParams = {
        page,
        offset,
        sortDirection,
        sortBy
    }
    if (type !== '') {
        pathParams.type = type
    }
    const request: GetProductsResponse & TotalProductCountResponse = await $axios
        .get<ProductDataResponse & TotalProductCountResponse & APIResponse>(API_BASE_URL + '/products/' + storeId + '/filters', {
            params: pathParams
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data.result,
                    count: res.data.count
                }
            }

            return {
                data: [],
                count: 0,
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: [],
                count: 0,
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    const haveDigitalProduct = request.count === 0 ? 'no' : 'yes'
    localStorage.setItem(DIGITAL_PRODUCT_FLAG_STORAGE_NAME, haveDigitalProduct)

    return request
}

export const SearchProducts = async (params: GetSearchProductsParams): Promise<GetProductsResponse> => {
    const request: GetProductsResponse = await $axios
        .get<SearchProductsItems>(API_BASE_URL + '/search', {
            params
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data.products
                }
            }

            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })

    return request
}

export const UpdateProduct = async (data: Product) => {
    const response = await $axios
        .put<Product>(API_BASE_URL + '/products', data)
        .then(async res => {
            if (res.status === 200) {
                return {
                    data: res.data
                }
            }
            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: [],
                error: {
                    field: '',
                    message: ''
                }
            }
        })
    return response
}

/**
 * Convert Digital product to Physical
 * @param storeId
 * @returns True/False
 */
export const ConvertDigitalToPhysical = async (storeId: string): Promise<ConvertDigitalToPhysicalMethodResponse> => {
    const request = $axios
        .put<APIResponse>(`${API_BASE_URL}/products/${storeId}/physical`, '')
        .then(res => {
            if (res.status === 200) {
                return {
                    data: true
                }
            }
            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })
    return request
}

export * from './bulk-upload'
export * from './inventory'
