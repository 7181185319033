interface FullscreenCenterizedLayoutProps {
    children: JSX.Element | JSX.Element[]
}

const FullscreenCenterizedLayout = ({ children }: FullscreenCenterizedLayoutProps) => {
    return (
        <>
            <main className="flex h-full w-full items-center justify-center overflow-auto bg-gray-100" data-testid="full-layout">
                <div className="my-auto max-h-full w-full">
                    <div className="p-4 sm:p-6">{children}</div>
                </div>
            </main>
        </>
    )
}

export default FullscreenCenterizedLayout
