export const routerTo = {
    account: {
        url: () => `/akun`
    },
    accountStore: {
        url: () => `/akun/toko`
    },
    accountShipping: {
        url: () => `/akun/pengiriman`
    },
    accountShippingAddress: {
        url: () => `/akun/pengiriman/alamat`
    },
    customer: {
        url: () => `/pemasukan/pelanggan`
    },
    customerAdd: {
        url: () => `/pemasukan/pelanggan/tambah`
    },
    customerAll: {
        url: () => `/pemasukan/pelanggan/semua`
    },
    customerDetail: {
        url: (id: string) => `/pemasukan/pelanggan/${id}`
    },
    customerDetailAddress: {
        url: (id: string) => `/pemasukan/pelanggan/${id}/alamat`
    },
    income: {
        url: () => `/pemasukan`
    },
    invoice: {
        url: () => `/pemasukan/invoice`
    },
    invoiceAdd: {
        url: () => `/pemasukan/invoice/baru`
    },
    invoiceEdit: {
        url: (id: string) => `/pemasukan/invoice/ubah/${id}`
    },
    product: {
        url: () => `/produk`
    },
    productAdd: {
        url: () => `/produk/tambah`
    },
    productEdit: {
        url: (id: string) => `/produk/${id}`
    },
    category: {
        url: () => `/produk/kategori`
    },
    categoryAdd: {
        url: () => `/produk/kategori/tambah`
    },
    categoryEdit: {
        url: (id: string) => `/produk/kategori/${id}`
    },
    order: {
        url: () => `/pemasukan/pesanan`
    },
    orderDetail: {
        url: (id: string, customerId?: string) => `/pemasukan/pesanan/${id}${!!customerId ? `?fromCustomer=${customerId}` : ''}`
    },
    expense: {
        url: () => `/pengeluaran`
    },
    payableInvoice: {
        url: (create = false) => `/pengeluaran/invoice${create ? `?createInvoice=1` : ``}`
    },
    payableInvoiceAdd: {
        url: (payable = false) => `/pengeluaran/invoice/baru${payable ? `?payable=1` : ``}`
    },
    payableInvoiceEdit: {
        url: (id: string) => `/pengeluaran/invoice/ubah/${id}`
    },
    underMaintenance: {
        url: () => `/under-maintenance`
    },
    supplier: {
        url: () => `/pengeluaran/vendor`
    },
    supplierAdd: {
        url: () => `/pengeluaran/vendor/tambah`
    },
    supplierDetail: {
        url: (id: string) => `/pengeluaran/vendor/${id}`
    },
    supplierDetailAddress: {
        url: (id: string) => `/pengeluaran/vendor/${id}/alamat`
    },
    completePayment: {
        url: (id: string) => `/complete-payment/${id}`
    },
    paymentActviation: {
        url: () => `/aktifasi-pembayaran`
    }
}
