import { useContext } from 'react'

import { InitialDataContext } from '~/components/Global/GlobalProvider'

export interface PopupProps {
    show: boolean
    children: JSX.Element
    popupHandle: (value: boolean, children: JSX.Element, onClose?: () => void, fullcreen?: boolean) => void
    closePopup: (onClose?: () => void) => void
}

export const PopupDefaultValue = {
    show: false,
    children: <></>,
    popupHandle: () => false,
    closePopup: () => false
}

const Popup = () => {
    const { popup } = useContext(InitialDataContext)

    if (!popup.show) {
        return <></>
    }

    return (
        <div className="fixed left-0 top-0 z-40 flex h-full w-full items-center justify-center bg-black bg-opacity-40">
            <div className="max-h-full w-full">
                <div className="mx-auto w-margined-full-width max-w-full rounded-md bg-neutrals-light shadow-md md:max-w-md">
                    {popup.children}
                </div>
            </div>
        </div>
    )
}

export default Popup
