export default {
    product_text: 'Produk',
    table_column_heading: {
        name: 'Nama Produk',
        price_without_discount: 'Harga (dengan diskon)',
        sku: 'SKU',
        stock: 'Stok Saat Ini'
    },
    edit_product: 'Ubah Detail',
    search_placeholder: 'Cari Nama Produk',
    filter_by_placeholder: 'Filter Kategori',
    sort_by_placeholder: 'Pembaruan Terakhir',
    add_product: 'Tambah Produk',
    bulk_product: 'Tambah banyak produk',
    add_from_excel: 'Tambah dari Excel',
    filter: {
        filter_by_category: 'Filter Kategori',
        sorting: 'Pembaruan Terakhir'
    },
    cancel: 'Batal ',
    change_stock: 'Ubah Stok',
    active: 'Aktif',
    inactive: 'Tidak Aktif',
    save: 'Simpan',
    yes_cancel: 'Ya, Batal',
    yes_sure: 'Ya, Yakin',
    return: 'Kembali',
    inventory_banner: {
        later_btn: 'Nanti saja',
        activate_feature: 'Aktifkan Fitur'
    },
    carry_on: 'Lanjut',
    update_stock_in_progress: 'Pengaktifan fitur stok sedang berlangsung…',
    stock_feature_popup: {
        updating_product_message: 'Memperbarui produk akan membutuhkan waktu. Harap tunggu beberapa saat.',
        stock_feature_title: 'Informasi Fitur Stok',
        digital_conversion_message: {
            p1: 'Fitur stok tidak berlaku untuk Produk Digital saat ini. Klik',
            p2: ' Lanjutkan ',
            p3: 'jika ingin mengubah produkmu menjadi Produk Fisik agar bisa aktifkan fitur stok.'
        },
        cancel_btn: 'Batalkan',
        continue_btn: 'Lanjutkan',
        max_99999_error_message: 'Hanya memakai angka dan maks. 99999',
        enter_initial_stock: 'Masukkan jumlah stok awal',
        stock_value_message: {
            p1: 'Stok awal akan ',
            p2: 'otomatis menjadi 10',
            p3: '. Silakan ubah stok produkmu di menu ',
            p4: 'Produk'
        },
        change_all_stock: 'Ubah Semua Stok',
        save: 'Simpan'
    },
    stock_error_message_0: 'Stok tidak boleh 0',
    help_popup: {
        title: 'Fitur Kelola Stok',
        step1_text1: 'Sekarang produkmu memiliki',
        step1_text2: 'keterangan jumlah stok',
        dan: 'dan',
        diatas: 'diatas',
        step1_text3: 'yang tersedia pada daftar produk',
        step2_text1: 'Sekarang kamu dapat mengelola semua stok produkmu dengan tombolk'
    },
    disable_inventory_Modal: {
        title: 'Lanjut nonaktifkan Fitur Kelola Stok?',
        message: 'SKU dan jumlah stok akan hilang jika kamu menonaktifkan fitur stok.',
        cancelled_btn: 'Batal',
        continue_btn: 'Ya, Lanjut'
    },
    inventory_active_message: 'Selamat! Fitur stok telah aktif. Yuk mulai perbarui stok barangmu secara berkala.',
    understand: 'Mengerti',
    manage_stock_msg:
        'Yuk, sesuaikan stok tiap produk di Tokoko dengan stok di gudang Juragan! Bisa atur stok satu-satu atau sekaligus buat semua produk.',
    lost_change_stock_msg: 'Jumlah stok yang kamu masukkan akan hilang jika kamu membatalkan ubah stok.',
    get_update_txt: 'Kamu akan memperbarui',
    update_stock_popup: 'Yakin untuk simpan stok?',
    stock_product: 'stok produk',
    api_msg: {
        update_stock: 'Ubah stok produk maksimal 20 produk dalam 1 kali ubah',
        no_validation_error: 'Hanya memakai angka dan maksimal 99999',
        cant_change_product: 'tidak dapat mengubah stok produk',
        product_update_success: 'stok produk berhasil berubah.'
    }
}
