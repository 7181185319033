export default {
    product: {
        total: 'Total Pesanan'
    },
    delivery: {
        fee: 'Ongkos kirim'
    },
    topInfo: {
        paymentInformation: 'Informasi Pembayaran',
        paymentMethod: 'Metode Pembayaran',
        top: 'Bayar Tempo',
        topPaymentInfo: 'Pembayaran akan masuk ke rekeningmu yang terdaftar maks. 2x24 jamsetelah pesanan selesai',
        maturityTime: 'Waktu Jatuh Tempo',
        pastMaturityTime: 'Lewat Jatuh Tempo',
        today: 'Hari ini'
    }
}
