import queryString from 'query-string'
import $axios from '~/lib/axios'
import locale from '~/locale'

import { PAYMENT_API_BASE_URL, TOKOKO_API_BASE_URL, USER_SERVICE_BASE_URL } from '~/constants'
import { logout, refreshToken } from '~/utils/auth'
import { closeWebView, delayedCallback } from '~/utils/common'
import { $log } from '~/utils/log'

import { Dispatch, SetStateAction } from 'react'
import { SnackbarItem } from '~/components/Layout/Dashboard/Snackbar'
import { routerTo } from '~/utils/page'
import $sentry from '~/utils/sentry'
import $posthog from './posthog'

/**
 * add authorization token on each request
 * @returns
 */
export const requestInterceptor = (xsrfToken?: string) => {
    $axios.interceptors.request.clear()
    $axios.interceptors.request.use(
        config => {
            if (xsrfToken) {
                config.headers['X-Xsrf-Token'] = xsrfToken
            }
            config.headers['X-Request-Source'] = 'seller-web'
            return config
        },
        error => {
            Promise.reject(error)
        }
    )
}

/**
 * when api response with 443 then call refresh token api
 * @param param
 * @returns
 */
export const responseInterceptor = (
    snackbarHandler: (arg: SnackbarItem) => void,
    setXsrfToken: Dispatch<SetStateAction<string | undefined>>
) => {
    $axios.interceptors.response.use(
        response => {
            return response
        },
        async error => {
            const originalRequest = error?.config
            $log('originalRequest', originalRequest)
            $log('error-response', error?.response)
            $log('error-response-status', error?.response?.status)

            if (error?.response?.status === 301) {
                window.location.href = routerTo.underMaintenance.url()
                return
            }

            if (
                (error?.response?.status === 443 || error?.response?.status === 401) &&
                (originalRequest?.url === `${USER_SERVICE_BASE_URL}/login/standalone` ||
                    originalRequest?.url === `${USER_SERVICE_BASE_URL}/users/bacon` ||
                    originalRequest?.url === `${USER_SERVICE_BASE_URL}/logout` ||
                    originalRequest?.url.startsWith(`${TOKOKO_API_BASE_URL}/public/api/v2/otp/send`))
            ) {
                return Promise.reject(error)
            }

            if ((error?.response?.status === 443 || error?.response?.status === 401) && !originalRequest?._retry) {
                originalRequest._retry = true

                const response = await refreshToken(true)

                if (response.isTokenRefresh && response.success) {
                    originalRequest.headers['X-Xsrf-Token'] = response.xsrfToken
                    setXsrfToken(response.xsrfToken)
                    $axios.interceptors.request.clear()
                    return $axios(originalRequest)
                }

                const redirect = originalRequest?.url !== `${USER_SERVICE_BASE_URL}/check-auth`

                removeCookieAndRedirectToLogin(snackbarHandler, redirect)
            }

            if (
                error.response.status === 400 &&
                originalRequest.url === `${TOKOKO_API_BASE_URL}/api/v2/store/management` &&
                originalRequest.method === 'get'
            ) {
                return Promise.reject(error)
            }

            if (originalRequest.url.startsWith(`${PAYMENT_API_BASE_URL}/accounts`) && originalRequest.method === 'get') {
                return Promise.reject(error)
            }

            if (originalRequest.url.startsWith(`${TOKOKO_API_BASE_URL}/api/v2/payment/checkout`) && originalRequest.method === 'post') {
                let apiResponseMsg = locale.id.common.response_error
                if (error?.response?.data?.responseMessages?.in) {
                    apiResponseMsg = error.response.data.responseMessages.in
                }
                if (error?.response?.data?.message) {
                    apiResponseMsg = error.response.data.message
                }

                if (apiResponseMsg.startsWith('aggregated_transaction_amount')) {
                    snackbarHandler({
                        value: true,
                        message: 'Anda telah melebihi batas transaksi harian',
                        style: 'error'
                    })
                } else if (apiResponseMsg.startsWith('current_transaction_amount')) {
                    snackbarHandler({
                        value: true,
                        message: 'Transaksi tidak sesuai limit, Min. 1.000.000 dan Max: 100.000.000',
                        style: 'error'
                    })
                } else {
                    snackbarHandler({
                        value: true,
                        message: 'Gagal membuat link pembayaran. Silahkan coba sesaat lagi!',
                        style: 'error'
                    })
                }

                return Promise.reject(error)
            }

            if (error?.response?.status === 403 || error?.response?.status === 401) {
                $log('removeCookieAndRedirectToLogin from', 3)
                removeCookieAndRedirectToLogin(snackbarHandler)
            }

            if (
                error?.response?.status !== 403 &&
                error?.response?.status !== 443 &&
                error?.response?.status !== 401 &&
                error?.response?.status !== 404
            ) {
                let apiResponseMsg = locale.id.common.response_error
                if (error?.response?.data?.responseMessages?.in) {
                    apiResponseMsg = error.response.data.responseMessages.in
                }
                if (error?.response?.data?.message) {
                    apiResponseMsg = error.response.data.message
                }
                snackbarHandler({
                    value: true,
                    message: apiResponseMsg,
                    style: 'error'
                })
            }

            return Promise.reject(error)
        }
    )
}

const removeCookieAndRedirectToLogin = async (snackbarHandler: (arg: SnackbarItem) => void, redirect = true) => {
    $posthog.reset()
    $sentry.clearUser()

    const isLoginPage = window.location.pathname === '/login'

    if (!isLoginPage) {
        await logout()
    }

    const { platform } = queryString.parse(window.location.search)
    if (platform === 'android') {
        delayedCallback(() => {
            snackbarHandler({
                value: true,
                message: locale.id.common.session_timeout,
                style: 'error',
                noTimeOut: true
            })
        }, 2500)
        delayedCallback(() => {
            closeWebView()
        }, 3000)
        return
    }

    if (!isLoginPage && redirect) window.location.href = '/login'
}
