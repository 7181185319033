export default {
    IDENTITY_DATA: {
        FIELD: {
            companyType: {
                error: 'Tipe Perusahaan wajib diisi',
                label: 'Tipe Perusahaan',
                label_info: '',
                placeholder: ''
            },
            businessType: {
                error: 'Bidang Bisnis wajib diisi',
                label: 'Bidang Bisnis',
                label_info: 'Wajib',
                placeholder: 'Pilih Tipe Bisnis'
            },
            companyName: {
                error: 'Nama Perusahaan wajib diisi',
                label: 'Nama Perusahaan',
                label_info: 'Sesuai dengan akta notaris atau dokumen legal',
                placeholder: 'Masukkan Nama Perusahaan'
            },
            name: {
                error: 'Nama Lengkap wajib diisi',
                label: 'Nama Lengkap',
                label_info: 'Sesuai dengan kartu identitas',
                placeholder: 'Masukkan Nama Lengkap'
            },
            phoneNumber: {
                error: 'Nomor HP wajib diisi',
                label: 'Nomor HP',
                label_info: 'Terdaftar di BukuWarung',
                placeholder: ''
            },
            ktpNumber: {
                error: 'NIK wajib diisi',
                label: 'NIK',
                label_info: 'Pastikan NIK kamu benar',
                placeholder: ''
            },
            idCardDocument: {
                error: 'Foto KTP wajib diisi',
                label: 'Foto KTP',
                label_info: '',
                placeholder: ''
            },
            birthPlace: {
                error: 'Tempat Lahir wajib diisi',
                label: 'Tempat Lahir',
                label_info: 'Sesuai dengan KTP',
                placeholder: ''
            },
            birthDate: {
                error: 'Tanggal Lahir wajib diisi',
                label: 'Tanggal Lahir',
                label_info: 'Sesuai dengan kartu identitas',
                placeholder: ''
            },
            month: {
                error: '',
                label: '',
                label_info: '',
                placeholder: 'Pilih Bulan'
            },
            date: {
                error: '',
                label: '',
                label_info: '',
                placeholder: 'Pilih Hari'
            },
            year: {
                error: '',
                label: '',
                label_info: '',
                placeholder: 'Pilih Tahun'
            },
            gender: {
                error: 'Jenis Kelamin wajib diisi',
                label: 'Jenis Kelamin',
                label_info: '',
                placeholder: ''
            },
            maritalStatus: {
                error: 'Status Perkawinan wajib diisi',
                label: 'Status Perkawinan',
                label_info: '',
                placeholder: ''
            },
            salesTeamId: {
                error: 'ID Tim Sales wajib diisi',
                label: 'Pilih Tim Sales',
                label_info: 'Tidak Wajib',
                placeholder: 'Nama tim sales'
            },
            photoKTP: {
                error: '',
                label: 'Foto KTP',
                label_info: '',
                placeholder: '',
                viewPhoto: 'Lihat Foto'
            },
            photoSelfiKTP: {
                error: '',
                label: 'Foto Selfie dengan KTP',
                label_info: 'Siapkan KTP Anda',
                placeholder: '',
                viewPhoto: 'Lihat Foto'
            }
        },
        SALES_TEAM_HEADER: 'Informasi Sales',
        SALES_TEAM_SUB_HEADER: 'Pilih nama Tim Sales yang mendatangimu. Kosongkan jika tidak tersedia.',
        BUSINESS_TYPE_HEADER: 'Bidang Bisnis',
        FILE_TYPES: 'Upload file (.pdf, .jpg, .png)'
    },
    BANK_AND_ADDRESS_DATA: {
        FIELD: {
            postalInfo: {
                error: 'Kode Pos wajib diisi',
                label: 'Kode Pos',
                label_info: '',
                placeholder: 'Tulis Kode Pos Areamu'
            },
            addressLine: {
                error: 'Detail Alamat wajib diisi',
                label: 'Detail Alamat',
                label_info: '',
                placeholder: 'Nama Jalan, Nomor Rumah/Apartemen, Nama gedung, dll'
            },
            bankName: {
                error: 'Nama Bank wajib diisi',
                label: 'Nama Bank',
                label_info: '',
                placeholder: 'Pilih Bank'
            },
            bankAccountNumber: {
                error: 'Nama Lengkap wajib diisi',
                label: 'Nomor Rekening Pribadi',
                label_info: 'Pastikan nomor rekening sesuai',
                placeholder: 'Masukkan nomor rekening'
            },
            valid: {
                error: 'Nomor rekening tidak valid',
                label: '',
                label_info: '',
                placeholder: ''
            }
        },
        BANK_INFO_HEADER: 'Informasi Bank',
        BANK_INFO_SUB_HEADER: 'Masukkan informasi bank kamu di sini'
    },
    CONFIRM_DATA: {
        FIELD: {
            isAgree: {
                error: 'Kode Pos wajib diisi',
                label: 'Saya sudah membaca dan setuju dengan semua pernyataan diatas',
                label_info: '',
                placeholder: ''
            }
        },
        BANK_INFO_HEADER: 'Informasi Bank',
        BANK_INFO_SUB_HEADER: 'Masukkan informasi bank kamu di sini'
    },
    TRUST_SECURE_HEADER: 'Data kamu aman tersimpan',
    TRUST_SECURE_HEADER_ICON: '✅',
    TRUST_SECURE_INFO: 'Data kamu hanya akan digunakan sesuai regulasi.',
    TRUST_SECURE_INFO_OTHER: 'Pelajari tentang data privasi',
    DOCUMENT_MAX_SIZE_ERROR: 'Ukuran gambar tidak boleh melebihi 5MB!',
    FORM_SUBMIT_ERROR: 'Terjadi kesalahan, silahkan coba lagi',
    FORM_SUBMIT_SUCCESS: 'Data Toko anda berhasil disimpan!',
    DATA_FETCH_ERROR: 'Terjadi kesalahan saat mengambil data.',
    tnc: {
        page: {
            title: 'Daftar Talangin Dulu'
        },
        message: {
            not_registered: 'Anda belum mendaftarkan diri',
            agreed: 'Anda sudah menyetujui syarat dan ketentuan. Tekan lanjut',
            checked_required: 'Harus centang!'
        },
        document_title: 'SYARAT DAN KETENTUAN PERJANJIAN BERSAMA',
        read_statement: 'Saya sudah membaca dan setuju dengan semua pernyataan diatas'
    },
    signature: {
        instructions: {
            example: 'Contoh foto tanda tangan yang benar'
        },
        message: {
            page_inaccessible: 'Tidak dapat mengakses halaman',
            camera_inaccessible: 'Tidak dapat menggunakan kamera',
            image_not_taken: 'Gambar belum ada, silahkan mengambil gambar',
            image_upload_failed: 'Gagal mengupload gambar. Silahkan coba lagi!',
            supplier_invalid: 'Supplier tidak valid',
            submitted: 'Data berhasil dikirim',
            signature_missing: 'Anda belum mengambil gambar tanda tangan'
        }
    },
    popup: {
        success: {
            title: 'Pengajuanmu berhasil dikirim!',
            content: {
                first_part: 'Data kamu sedang dalam proses pengecekan maksimal',
                time: '2 minggu',
                last_part: 'kamu lagi melalui WhatsApp'
            },
            button: 'Mengerti'
        }
    },
    BnplRestrictPopup: {
        title: 'Verifikasi Data Diri Sedang Diproses',
        Body: 'Anda bisa melanjutkan mengisi formulir setelah verifikasi disetujui. Mohon coba secara berkala.'
    }
}
