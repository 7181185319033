import { INVOICE_API_BASE_URL, IS_PROD, TOKOKO_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import { APIDataResponse, APIResponse, DataResponse, PaginationResponse, PhoneValidationResponse } from '~/types'
import { CreateVendorResponse, CreateVendorSendRequest, VendorBankDetails, VendorData } from '~/types/vendor-management'
import { getErrorMessage } from '~/utils/api'

export const GetVendorByName = async (
    name: string,
    storeId: string,
    pageNo: number,
    pageSize: number
): Promise<DataResponse<VendorData[]>> => {
    const response = await $axios
        .get<APIResponse & APIDataResponse<{ vendorList: VendorData[]; pagination?: PaginationResponse }>>(
            `${TOKOKO_API_BASE_URL}/api/v2/vendor/management/search`,
            {
                params: {
                    storeId,
                    name,
                    pageNo,
                    pageSize
                }
            }
        )
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.data.vendorList,
                    pagination: res.data.data.pagination
                }
            }

            return {
                data: [],
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage([], e)
        })

    return response
}

export const CreateVendor = async (data: CreateVendorSendRequest[]): Promise<DataResponse<VendorData[]>> => {
    const request: any = await $axios
        .put<APIResponse & CreateVendorResponse>(`${TOKOKO_API_BASE_URL}/api/v2/vendor/management`, data, {
            params: {
                storeId: data[0].storeId
            }
        })
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.data
                }
            }
            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}

export const GetVendors = async (
    storeId: string,
    pageSize: number,
    pageNo: number,
    filterBy: string,
    orderBy: string
): Promise<DataResponse<VendorData[]>> => {
    const request = await $axios
        .get<APIResponse & APIDataResponse<{ vendorList: VendorData[]; pagination?: PaginationResponse }>>(
            `${TOKOKO_API_BASE_URL}/api/v2/vendor/management/list`,
            {
                params: {
                    storeId,
                    pageSize,
                    pageNo: pageNo - 1,
                    filterBy,
                    orderBy
                }
            }
        )
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.data.vendorList,
                    pagination: res.data.data.pagination
                }
            }

            return {
                data: [],
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage([], e)
        })

    return request
}

export const DefaulVendorData = {
    vendorId: '',
    storeId: '',
    name: '',
    phoneNumber: '',
    address: '',
    bankDetails: [
        {
            bankId: '',
            bankCode: '',
            bankName: '',
            bankLogo: '',
            bankAccountNumber: '',
            bankHolderName: ''
        }
    ],
    totalPurchasedAmount: 0,
    createdAt: 0,
    updatedAt: 0
}

export const GetVendor = async (storeId: string, vendorId: string): Promise<DataResponse<VendorData>> => {
    const request = await $axios
        .get<APIResponse & APIDataResponse<VendorData>>(
            `${TOKOKO_API_BASE_URL}/api/v2/vendor/management?storeId=${storeId}&vendorId=${vendorId}`
        )
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.data
                }
            }

            return {
                data: DefaulVendorData,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage(DefaulVendorData, e)
        })

    return request
}

export const vendorPhoneNumberValidation = async (storeId: string, phone: string, phoneOnly: string): Promise<PhoneValidationResponse> => {
    if (phoneOnly.length <= 5 || phoneOnly.length > 13) {
        return {
            data: {
                phoneNumberAvailable: false,
                validPhoneNumber: false
            }
        }
    }

    if (/^11.*11$/.test(phoneOnly) && !IS_PROD) {
        return {
            data: {
                phoneNumberAvailable: true,
                validPhoneNumber: true
            }
        }
    }

    const response: PhoneValidationResponse = await $axios
        .get<
            APIResponse &
                APIDataResponse<{
                    message: string
                    phoneNumberAvailable: boolean
                    validPhoneNumber: boolean
                }>
        >(`${TOKOKO_API_BASE_URL}/api/v2/vendor/management/validate/phone`, {
            params: {
                storeId,
                phoneNumber: phone
            }
        })
        .then(res => {
            if (res.data.responseType == 'SUCCESS') {
                return {
                    data: res.data.data
                }
            }
            return {
                data: res.data.data,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({ validPhoneNumber: false, phoneNumberAvailable: false }, e)
        })
    return response
}

export const DeleteVendor = async (storeId: string, vendorId: string): Promise<DataResponse<boolean>> => {
    const request: any = await $axios
        .delete<APIResponse & { data?: boolean }>(`${TOKOKO_API_BASE_URL}/api/v2/vendor/management?storeId=${storeId}&vendorId=${vendorId}`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: true
                }
            }
            return {
                data: false,
                error: {
                    field: 'general',
                    message: 'Gagal menghapus vendor'
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}

export const UpdateVendorBankAccountInvoice = async (
    invoiceId: string,
    data: VendorBankDetails
): Promise<DataResponse<VendorBankDetails>> => {
    const response = await $axios
        .put(`${INVOICE_API_BASE_URL}/vendor/bank?invoiceId=${invoiceId}`, data)
        .then(res => ({ data: res.data }))
        .catch(e => getErrorMessage(data, e))

    return response
}
