import $axios from '~/lib/axios'
import locale from '~/locale'

import { API_BASE_URL, IS_PROD } from '~/constants'
import {
    APIResponse,
    CreateCustomerSendRequest,
    CreateSingleCustomerResponse,
    CustomerGetOrderResponse,
    CustomerStatusData,
    DataResponse,
    GetCustomerOrderHistory,
    OrdersParams,
    PhoneNumberValidation,
    PhoneValidationResponse,
    SearchCustomerAPIResponse,
    searchCustomerResponse,
    SingleCustomerCreatedResponse,
    StoreDetail
} from '~/types'
import { getErrorMessage } from '~/utils/api'

export const GetCustomerStatus = (status: string) => {
    let statusData: CustomerStatusData
    switch (status) {
        case 'WHITELISTED':
            statusData = {
                status: locale.id.customer.customer_status.whitelisted,
                bgColorClass: 'bg-blue-500',
                type: 'success'
            }
            break
        case 'REJECTED':
            statusData = {
                status: locale.id.customer.customer_status.rejected,
                bgColorClass: 'bg-red-500',
                type: 'error'
            }
            break
        case 'PENDING':
            statusData = {
                status: locale.id.customer.customer_status.pending,
                bgColorClass: 'bg-yellow-300',
                type: 'warning'
            }
            break
        case 'Added':
            statusData = {
                status: locale.id.customer.customer_status.pending,
                bgColorClass: 'bg-yellow-300',
                type: 'warning'
            }
            break
        case 'REFERRED':
            statusData = {
                status: locale.id.customer.customer_status.referred,
                bgColorClass: 'bg-yellow-300',
                type: 'warning'
            }
            break
        case 'Referred':
            statusData = {
                status: locale.id.customer.customer_status.referred,
                bgColorClass: 'bg-yellow-300',
                type: 'warning'
            }
            break
        default:
            statusData = {
                status: 'Belum Diajukan',
                bgColorClass: 'bg-neutrals-300',
                type: 'tertiary'
            }
    }

    return statusData
}

export const GetCustomers = async (storeId: string, limit: number, page: number, sortBy: string, orderBy: string, filterBy = 'ALL') => {
    const request = await $axios
        .get(API_BASE_URL + `/customers/${storeId}?limit=${limit}&page=${page}&sortBy=${sortBy}&order=${orderBy}&filterBy=${filterBy}`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

export const GetTopCustomers = async (storeId: string) => {
    const request = await $axios
        .get(API_BASE_URL + `/customers/analytics/${storeId}?direction=DESC&limit=10&orderBy=totalOrderAmount`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

export const SortByTypes = () => {
    return [
        {
            text: locale.id.customer.sort_by.recent_customer, // Latest Customer Added
            value: 'newestCustomer'
        },
        {
            text: locale.id.customer.sort_by.oldest_customer, // Oldest Customers Added
            value: 'oldestCustomer'
        },
        {
            text: locale.id.customer.sort_by.alphabetical, // alphabet
            value: 'name'
        }
        // {
        //   text: locale.id.customer.sort_by.highest_payout, // Highest Payout
        //   value: 2
        // },
        // {
        //   text: locale.id.customer.sort_by.most_orders, // Most Orders
        //   value: 3
        // }
    ]
}

export const SaveCustomerReferral = async (
    postData: {
        createdByUser: string
        customerId: string
        status: string
        storeCustomerReferralsId: string
        updatedByUser: string
    },
    storeId: string
) => {
    const request = await $axios
        .post(API_BASE_URL + `/customers/referral/${storeId}`, postData)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

export const GetCustomerShortNames = (name: string) => {
    if (!name) {
        return '-'
    }

    return name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .toUpperCase()
        .substring(0, 3)
}

export const ToCamelCase = (name: string) => {
    return (' ' + name).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
        return ' ' + chr.toUpperCase()
    })
}

export const ToPascalCase = (name = '') => {
    let displayName = ''
    const nameArr = name.split(' ')
    nameArr.forEach(item => {
        displayName += item.charAt(0).toUpperCase() + item.slice(1)
        displayName += ' '
    })
    return displayName.trimEnd()
}

export const camelCaseToSnakeCaseInObjParams = (obj: any) => {
    const newObject: any = {}
    for (const camel in obj) {
        newObject[camel.replace(/([A-Z])/g, '_$1').toLowerCase()] = obj[camel]
    }
    return newObject
}

export const GetCustomerOrdersHistory = async (
    store: StoreDetail,
    customerId: string,
    params: OrdersParams
): Promise<CustomerGetOrderResponse> => {
    const request: CustomerGetOrderResponse = await $axios
        .get<APIResponse & GetCustomerOrderHistory>(`${API_BASE_URL}/orders/${store.storeId}/customers/${customerId}`, {
            params
        })
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.result
                }
            }
            return {
                data: [],
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage([], e)
        })
    return request
}

export const phoneNumberValidation = async (storeId: string, phone: string, phoneOnly: string): Promise<PhoneValidationResponse> => {
    if (phoneOnly.length <= 5 || phoneOnly.length > 13) {
        return {
            data: {
                phoneNumberAvailable: false,
                validPhoneNumber: false
            }
        }
    }

    if (/^11.*11$/.test(phoneOnly) && !IS_PROD) {
        return {
            data: {
                phoneNumberAvailable: true,
                validPhoneNumber: true
            }
        }
    }

    const response: PhoneValidationResponse = await $axios
        .get<APIResponse & PhoneNumberValidation>(`${API_BASE_URL}/customers/phone/validate/${storeId}/${phone}`)
        .then(res => {
            if (res.data.responseType == 'SUCCESS') {
                return {
                    data: res.data
                }
            }
            return {
                data: res.data,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({ validPhoneNumber: false, phoneNumberAvailable: false }, e)
        })
    return response
}

export const searchCustomerByName = async (
    searchText: string,
    limit: number,
    order: string,
    page: number,
    sort: string,
    storeId: string
): Promise<SearchCustomerAPIResponse> => {
    const response: SearchCustomerAPIResponse = await $axios
        .get<APIResponse & searchCustomerResponse>(
            `${API_BASE_URL}/search/searchCustomer/${storeId}?limit=${limit}&name=${searchText}&orderBy=${order}&page=${page}&sortBy=${sort}`
        )
        .then(res => {
            if (res.data.responseType == 'SUCCESS') {
                return {
                    data: res.data
                }
            }
            return {
                data: res.data,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({ response: [], count: 0 }, e)
        })
    return response
}

export const CreateCustomer = async (storeId: string, data: CreateCustomerSendRequest[]): Promise<CreateSingleCustomerResponse> => {
    const request: any = await $axios
        .put<APIResponse & { data?: SingleCustomerCreatedResponse[] }>(`${API_BASE_URL}/api/v2/store/customer/store/${storeId}/buyer`, data)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.data
                }
            }
            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}

export const DeleteCustomer = async (storeId: string, storeCustomerId: string, phoneNumber: string): Promise<DataResponse<boolean>> => {
    const request: any = await $axios
        .delete<APIResponse & { data?: boolean }>(
            `${API_BASE_URL}/api/v2/store/customer/delete?storeId=${storeId}&storeCustomerId=${storeCustomerId}&buyerPhoneNumber=${phoneNumber}`
        )
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: true
                }
            }
            return {
                data: false,
                error: {
                    field: 'general',
                    message: 'Gagal menghapus pelanggan'
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}

export * from './details'
