import { Order } from '~/types'

export * from './card'
export * from './config'

export const OrderDefaultData: Order = {
    addrArea: '',
    addrAreaId: '',
    addrCity: '',
    addrCityId: '',
    addrId: '',
    addrLatitude: 0,
    addrLongitude: 0,
    addrProvince: '',
    addrProvinceId: '',
    addrStreet: '',
    addrSuburb: '',
    addrSuburbId: '',
    addrTitle: '',
    address: '',
    bnplDetails: {
        loanId: '',
        loanType: '',
        tenureInWeeks: 1,
        product: {
            adminFee: '',
            description: '',
            heading: '',
            id: '',
            interest_rate: 0,
            lateFee: '',
            loanProductType: '',
            subHeading: '',
            tenureInWeeks: 0,
            validity_in_days: 0
        }
    },
    buyerInfo: {
        address: '',
        createdAt: 0,
        name: '',
        phone: '',
        updatedAt: 0,
        userId: ''
    },
    complain: {
        complainStatus: '',
        complainStatusUpdates: [],
        description: '',
        expectedSolution: '',
        images: [],
        itemStatus: '',
        refundAccount: {
            accountNumber: '',
            accountOwnerName: '',
            bankCode: ''
        },
        rejectionEvidences: [],
        rejectionExplanation: '',
        rejectionReason: '',
        type: ''
    },
    courierCompany: '',
    courierDriverName: '',
    courierDriverPhone: '',
    courierLink: '',
    courierTrackingId: '',
    courierType: '',
    courierWaybillId: '',
    createdAt: 0,
    deliveryCost: 0,
    deliveryType: 'DELIVERY',
    itemList: [],
    name: '',
    notes: '',
    orderId: '',
    orderImage: '',
    orderStatusUpdates: [],
    orderTransactionType: 'BUYERWEB',
    orderTime: 0,
    paymentDetails: {
        amount: '',
        beneficiary: {
            code: '',
            name: '',
            number: '',
            type: ''
        },
        createdAt: '',
        expiredAt: '',
        paidAt: '',
        paymentId: '',
        paymentUrl: '',
        status: '',
        updatedAt: 123123123123
    },
    paymentMethod: null,
    paymentStatus: '',
    paymentStatusUpdates: [],
    phone: '',
    productType: '',
    rateId: 0,
    rateName: '',
    readyForDelivery: false,
    selectedCount: '',
    selectedProducts: '',
    shipmentId: '',
    shipmentProvider: '',
    shipmentStatus: '',
    shipmentStatusUdpates: [],
    shipperOrderId: '',
    status: 'NEW',
    storeId: '',
    telephone: '',
    totalOrderAmount: 0,
    topDueDate: '',
    topDueDays: 0,
    totalprice: 0,
    trackingID: '',
    updatedAt: 0,
    userId: '',
    weight: 0
}
