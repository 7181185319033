import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { APIResponse, ExpirationTimeItem, GetExpirationTimeResponse } from '~/types'
import { getErrorMessage } from '~/utils/api'

export const GetExpirationTime = async (orderId: string): Promise<GetExpirationTimeResponse> => {
    const request: GetExpirationTimeResponse = await $axios
        .get<ExpirationTimeItem & APIResponse>(API_BASE_URL + '/orders/payment-method/order-status/expiration-time-config/' + orderId)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.expirationTime
                }
            }

            return {
                data: 0,
                error: {
                    field: '',
                    message: ''
                }
            }
        })
        .catch(e => {
            return getErrorMessage<number>(0, e)
        })

    return request
}
