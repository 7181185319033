import axios from 'axios'

const $axios = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'x-app-version-code': '5050',
        'x-app-version-name': 'android',
        'buku-origin': 'tokoko',
        'X-Request-Source': 'seller-web',
        'x-app-user': 'Web',
        'x-app-device': 'Mac'
    }
})

export default $axios
