export default {
    customer_list: 'Daftar Pelanggan',
    add_customer: 'Tambah Pelanggan',
    invalid_phone_number: 'Nomor tidak sesuai',
    existing_phone_number: 'Nomor sudah terpakai',
    customer: 'Pelanggan',
    full_name: 'Nama Lengkap',
    purchase_date: 'TANGGAL PEMBELIAN',
    total_payment: 'TOTAL PEMBAYARAN',
    total: 'JUMLAH',
    order_amount: 'JUMLAH PESANAN',
    payment_method: 'METODE PEMBAYARAN',
    shipping_method: 'METODE PENGIRIMAN',
    change_name: 'Ubah Nama',
    customer_popup: {
        title: 'Mau ajukan Talangin Dulu ke pelanggan?',
        contact_you: 'Setelah ini tim Tokoko akan segera menghubungi ',
        data_verification: ' untuk verifikasi data.'
    },
    customer_table_heading: {
        full_name: 'NAMA LENGKAP',
        total_payment: 'TOTAL PEMBAYARAN',
        order_amount: 'JUMLAH PESANAN',
        status: 'STATUS TALANGIN DULU'
    },
    customer_filter_buttons: {
        all: 'Semua',
        whitelisted: 'Talangin Dulu • DITERIMA',
        not_initiated: 'Talangin Dulu • BELUM DIAJUKAN',
        top: 'Bayar Tempo'
    },
    customer_search_placeholder: 'Cari Nama Pelanggan',
    apply_gutter_first: 'Ajukan Talangin Dulu',
    detail: {
        header: {
            add: {
                title: 'Tambah Alamat',
                action: {
                    save: 'Simpan'
                }
            },
            edit: {
                title: 'Ubah Alamat',
                action: {
                    save: 'Ubah'
                }
            }
        },
        edit_form: {
            name: { label: 'Nama Lengkap' }
        },
        form: {
            name: {
                label: 'Nama Penjual',
                placeholder: 'Masukkan Nama Lengkap',
                error: {
                    required: 'Nama tidak boleh kosong'
                }
            },
            city: {
                label: 'Kota/Kecamatan',
                placeholder: 'Pilih Kota/Kecamatan'
            },
            postal_code: {
                label: 'Kode Pos (Tidak Wajib)',
                placeholder: 'Masukkan Kode Pos',
                caption: 'Masukkan 5 digit kode pos',
                error: {
                    required: 'Kode pos tidak boleh kosong'
                }
            },
            address: {
                label: 'Deskripsi Alamat',
                placeholder: 'Tulis nomor rumah, Blok, RT/RW, Patokan, dll'
            },
            coordinate: {
                label: 'Titik Alamat',
                choosen: 'Titik alamat terpilih',
                edit: 'Ubah',
                button: 'Tandai Lokasi'
            },
            phone: {
                label: 'Nomor HP'
            },
            success_message: 'Data pelanggan berhasil disimpan!',
            saved_success_message: 'Nama Pelanggan berhasil di ganti'
        }
    },
    customer_status: {
        whitelisted: 'Diterima',
        rejected: 'Ditolak',
        pending: 'Diproses',
        referred: 'Diajukan'
    },
    shop_now: 'Yuk, ajukan pelangganmu untuk bisa pakai metode pembayaran Talangin Dulu! Belanja Sekarang, Bayarnya di -Talangin Dulu 🛒',
    apply_bailout: `Klik tombol 'Ajukan' untuk ajukan Talangin Dulu untuk pelanggan. Baca Talangin Dulu selengkapnya `,
    sort_by: {
        alphabetical: 'Nama A-Z',
        recent_customer: 'Pelanggan Terbaru',
        oldest_customer: 'Pelanggan Terlama',
        highest_payout: 'Pembayaran Tertinggi',
        most_orders: 'Pesanan Terbanyak'
    },
    customer_details: 'Detail Pelanggan',
    here: 'di sini',
    customer_information: 'Informasi Pelanggan',
    mobile_number: 'Nomor HP',
    address: 'Alamat',
    order_history: 'Riwayat Pesanan',
    top_payment_information: 'Informasi Bayar Tempo',
    top_markup_percentage: 'Harga Jual Khusus',
    top_remaining_limit: 'Total Limit Belanja',
    top_total_limit: 'Sisa Limit Belanja',
    no_order: 'Kamu belum punya pesanan',
    share_shop_link_text: ' Yuk, rajin bagikan link tokomu biar pelanggan bisa lihat jualan kamu!',
    customer_not_found_heading: 'Kamu belum punya pelanggan',
    customer_not_found_info: 'Yuk, rajin bagikan link tokomu biar pelanggan bisa lihat jualan kamu!',
    home_page_breadcrumb: 'Beranda',
    all_time_text: 'Semua Waktu',
    view_all: 'Lihat Semua',
    total_customers: 'Total Pelanggan',
    total_bnpl_customers: 'Total Pelanggan Talangin Dulu',
    total_top_customers: 'Total pelanggan Bayar Tempo',
    top_customers: 'Pelanggan Teratas',
    list_of_customers: 'Berikut adalah daftar [0] pelanggan terbaik kamu!',
    table_column_heading: {
        no: 'No',
        name: 'NAMA LENGKAP',
        status: 'STATUS TALANGIN DULU',
        total_payment: 'TOTAL PEMBAYARAN',
        total_order_amount: 'JUMLAH PESANAN',
        total_payment_mobile: 'Total Pembayaran',
        total_order_amount_mobile: 'Jumlah Pesanan'
    },
    all_customers: 'Semua Pelanggan',
    view_customer_detail: 'Lihat Detail',
    account_page: {
        account_settings: 'Pengaturan Akun',
        store_settings: 'Pengaturan Toko',
        shipping_arrangements: 'Pengaturan Pengiriman ',
        store_stock_features: 'Fitur Stok Toko',
        stock_message: ' Kamu akan dapat mengatur stok pada setiap produkmu dan dapat memantau stok keluar atau stok masuk ke akun Tokoko.',
        go_out: 'Keluar'
    }
}
