import { INVOICE_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import { APIDataResponse, APIResponse, DataResponse } from '~/types'
import {
    CancelInvoiceResponse,
    CreateInvoiceParams,
    CreatePaymentRecordResponse,
    GetInvoiceDetailsMethodResponse,
    GetInvoiceDetailsResponse,
    GetInvoiceInfoMethodResponse,
    InvoiceApiCommonResponse,
    InvoiceApiParams,
    InvoiceApiResponse,
    PaymentRecordRequest,
    updateNonDraftInvoiceParamsResponse
} from '~/types/invoice'
import { getErrorMessage } from '~/utils/api'

/**
 * get Invoice List
 * @returns invoice detail with different statuses
 */
export const GetInvoiceInfo = async (params?: InvoiceApiParams): Promise<GetInvoiceInfoMethodResponse> => {
    const request = await $axios
        .get<APIDataResponse<InvoiceApiResponse>>(`${INVOICE_API_BASE_URL}/supplier/list`, {
            params
        })
        .then(res => {
            if (res.status === 200) {
                return {
                    data: res.data.data.invoiceList,
                    pagination: res.data.data.pagination
                }
            }
            return {
                data: [],
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage([], e)
        })

    return request
}

/**
 * get Invoice Details
 * @param invoiceId
 * @returns invoice detail for specific status
 */
export const GetInvoiceDetails = async (invoiceId: string): Promise<GetInvoiceDetailsMethodResponse> => {
    const request = await $axios
        .get<APIDataResponse<GetInvoiceDetailsResponse>>(`${INVOICE_API_BASE_URL}/${invoiceId}`)
        .then(res => {
            if (res.status === 200) {
                return { data: res.data.data }
            }
            return {
                data: { status: 'DRAFT', products: [], productTotalCost: 0, totalCost: 0 } as GetInvoiceDetailsResponse,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<GetInvoiceDetailsResponse>(
                {
                    status: 'DRAFT',
                    products: [],
                    productTotalCost: 0,
                    totalCost: 0
                },
                e
            )
        })

    return request
}

/**
 * create Invoice
 * @param requestBody
 * @returns invoice detail for specific status
 */
export const CreateNewInvoice = async (requestBody: CreateInvoiceParams): Promise<DataResponse<string | undefined>> => {
    const request = await $axios
        .post<APIDataResponse<string>>(`${INVOICE_API_BASE_URL}`, requestBody)
        .then(res => ({ data: res.data.data }))
        .catch(e => getErrorMessage(undefined, e))

    return request
}

/**
 * update Invoice
 * @param invoiceId
 * @returns result
 */
export const updateDraftInvoice = async (draftId: string, requestBody: CreateInvoiceParams): Promise<any> => {
    const request = await $axios
        .put<APIDataResponse<updateNonDraftInvoiceParamsResponse>>(
            `${INVOICE_API_BASE_URL}/draft/${draftId}`,

            requestBody
        )
        .then(res => {
            if (res.status === 200) {
                return { data: res.data }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

/*
 * Cancel invoice
 */
export const CancelInvoice = async (invoiceId: string): Promise<CancelInvoiceResponse> => {
    const request = await $axios
        .put<APIResponse & CancelInvoiceResponse>(`${INVOICE_API_BASE_URL}/inv/${invoiceId}/cancel`)
        .then(res => {
            if (res.status === 200) {
                return { result: true, message: res.data.message }
            } else {
                return {
                    result: false,
                    error: {
                        field: 'general',
                        message: 'tidak dapat memperbarui status faktur'
                    }
                }
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

export const deleteInvoice = async (draftId: string): Promise<any> => {
    const request = await $axios
        .delete<APIDataResponse<InvoiceApiCommonResponse>>(`${INVOICE_API_BASE_URL}/draft/${draftId}`)
        .then(res => {
            if (res.status === 200) {
                return { data: res.data }
            }
            return {
                data: {}
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    return request
}

export const CreatePaymentRecord = async (params: PaymentRecordRequest): Promise<DataResponse<boolean>> => {
    const request = await $axios
        .post<CreatePaymentRecordResponse>(`${INVOICE_API_BASE_URL}/payment`, params)
        .then(res => {
            if (res.data.result) {
                return { data: true }
            } else {
                return {
                    data: false,
                    error: {
                        field: 'general',
                        message: 'tidak dapat membuat catatan pembayaran'
                    }
                }
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })

    return request
}

export const UpdatePaymentRecord = async (paymentId: string, params: PaymentRecordRequest): Promise<DataResponse<boolean>> => {
    const request = await $axios
        .put<APIDataResponse>(`${INVOICE_API_BASE_URL}/payment/${paymentId}`, params)
        .then(res => {
            if (res.data.result) {
                return { data: true }
            } else {
                return {
                    data: false,
                    error: {
                        field: 'general',
                        message: 'tidak dapat memperbarui catatan pembayaran'
                    }
                }
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })

    return request
}

export const DeletePaymentRecord = async (
    paymentId: string,
    params: Pick<PaymentRecordRequest, 'invoiceId'>
): Promise<DataResponse<boolean>> => {
    const request = await $axios
        .delete<APIDataResponse>(`${INVOICE_API_BASE_URL}/payment/${paymentId}`, {
            params
        })
        .then(res => {
            if (res.data.result) {
                return { data: true }
            } else {
                return {
                    data: false,
                    error: {
                        field: 'general',
                        message: 'tidak dapat menghapus catatan pembayaran'
                    }
                }
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })

    return request
}
