export default {
    invoice_text: 'Invoice',
    new_bill: 'Invoice Baru',
    invoice_date: 'Tanggal',
    status_invoice: 'Status Invoice',
    create_new_bill: 'Buat Invoice Baru',
    cari: 'Cari',
    table_column_heading: {
        status: 'STATUS',
        code: 'NOMOR',
        total_cost: 'NOMINAL',
        date: 'TANGGAL',
        due_date: 'JATUH TEMPO',
        customer: 'PELANGGAN',
        payment_source: 'METODE PEMBAYARAN',
        vendor: 'VENDOR',
        category: 'KATEGORI',
        paid: 'SUDAH DIBAYAR',
        unpaid: 'SISA TAGIHAN'
    },
    status_table_heading: {
        name: 'NAMA PRODUK',
        unit_price: 'HARGA SATUAN',
        discount: 'DISKON',
        amount: 'JUMLAH',
        total_price: 'TOTAL HARGA'
    },
    invoice_status: {
        draft: 'Draft',
        not_paid: 'Belum Dibayar',
        paid: 'Lunas',
        partially_paid: 'Dibayar Parsial',
        cancel: 'Batal'
    },
    popup: {
        flag_paid: 'Tandai Sudah Dibayar'
    },
    no_invoice_text: 'Catat Invoice, Kirim, dan Terima Uangnya!',
    no_invoice_on_filter_title: 'Tidak ada invoice yang sesuai dari filter ini',
    no_invoice_on_filter_subtitle: 'Coba kurangi filter yang kamu pilih atau atur ulang filter.',
    search_placeholder: 'Cari nama pelanggan atau invoice',
    menu_items: {
        view_bill_history: 'Lihat Riwayat Invoice',
        save_pdf: 'Simpan PDF',
        save_pdf_small: 'Simpan PDF layout Kecil',
        print_bill: 'Print invoice besar (A4)',
        print_bill_medium: 'Print invoice sedang (75mm)',
        print_bill_small: 'Print invoice kecil (50mm)',
        cancel_bill: 'Batalkan Invoice'
    },
    print: {
        date: 'TANGGAL',
        due_date: 'TANGGAL JATUH TEMPO',
        small_layout_date: 'Dibuat:',
        small_layout_due_date: 'Jatuh Tempo:',
        small_layout_items: 'Barang',
        small_layout_total: 'Total',
        sender: {
            info: 'INFORMASI PENGIRIM'
        },
        receiver: {
            info: 'INFORMASI PENERIMA'
        },
        table: {
            product_name: 'NAMA PRODUK',
            discount: 'DISKON',
            price: 'HARGA SATUAN',
            quantity: 'JUMLAH',
            total: 'TOTAL HARGA',
            subtotal: 'Subtotal',
            item: 'Barang',
            delivery_fee: 'Ongkos Kirim',
            additional_discount: 'Diskon Tambahan',
            total_price: 'TOTAL',
            already_paid: 'Sudah Dibayar',
            remaining_paid: 'Sisa Pembayaran',
            payment_method: 'Metode Pembayaran',
            payment_method_enum: {},
            history: {
                title: 'RIWAYAT PEMBAYARAN',
                no: 'NO.',
                date: 'TANGGAL',
                paid: 'SUDAH BAYAR',
                method: 'METODE PEMBAYARAN'
            }
        },
        note: 'KETERANGAN',
        small_layout_note: 'Keterangan',
        footer: {
            left: 'Dibuat pakai aplikasi',
            center: 'Terakhir diperbarui:'
        }
    }
}
