import { IconsProps } from '~/types'

const IconInfoRed = ({ className }: IconsProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 24 25" fill="none">
            <path
                d="M21.1004 12.4999C21.1004 14.9134 20.1416 17.228 18.4351 18.9346C16.7285 20.6412 14.4139 21.5999 12.0004 21.5999C9.58692 21.5999 7.2723 20.6412 5.56572 18.9346C3.85914 17.228 2.90039 14.9134 2.90039 12.4999C2.90039 10.0864 3.85914 7.77181 5.56572 6.06523C7.2723 4.35865 9.58692 3.3999 12.0004 3.3999C14.4139 3.3999 16.7285 4.35865 18.4351 6.06523C20.1416 7.77181 21.1004 10.0864 21.1004 12.4999ZM13.2025 18.502C13.5213 18.1832 13.7004 17.7508 13.7004 17.2999C13.7004 16.849 13.5213 16.4166 13.2025 16.0978C12.8837 15.779 12.4513 15.5999 12.0004 15.5999C11.5495 15.5999 11.1171 15.779 10.7983 16.0978C10.4795 16.4166 10.3004 16.849 10.3004 17.2999C10.3004 17.7508 10.4795 18.1832 10.7983 18.502C11.1171 18.8208 11.5495 18.9999 12.0004 18.9999C12.4513 18.9999 12.8837 18.8208 13.2025 18.502ZM12.0004 5.9999C11.5495 5.9999 11.1171 6.17901 10.7983 6.49782C10.4795 6.81663 10.3004 7.24903 10.3004 7.6999V12.4999C10.3004 12.9508 10.4795 13.3832 10.7983 13.702C11.1171 14.0208 11.5495 14.1999 12.0004 14.1999C12.4513 14.1999 12.8837 14.0208 13.2025 13.702C13.5213 13.3832 13.7004 12.9508 13.7004 12.4999V7.6999C13.7004 7.24903 13.5213 6.81663 13.2025 6.49782C12.8837 6.17901 12.4513 5.9999 12.0004 5.9999Z"
                fill="#E50707"
                stroke="#E50707"
            />
        </svg>
    )
}

export default IconInfoRed
