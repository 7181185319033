import locale from '~/locale'

import { ErrorField } from '~/types'
import { $error } from '~/utils/log'

type CatchErrorHandling = <T>(
    data: T,
    e: any
) => {
    data: T
    error: ErrorField
}

export const getErrorMessage: CatchErrorHandling = (data, e) => {
    $error(e)
    const _default = {
        data,
        error: {
            field: 'general',
            message: locale.id.common.request_error
        }
    }

    if (!e.response) {
        return _default
    }

    if (!e.response.data) {
        return _default
    }

    if (!e.response.data.responseMessages) {
        if (!e.response.data.message) {
            return _default
        }

        return {
            data,
            error: {
                field: 'general',
                message: e.response.data.message
            }
        }
    }

    return {
        data,
        error: {
            field: 'general',
            message: e.response.data.responseMessages.in
        }
    }
}

export const multiPromiseResolver = <T>(promises: Promise<any>[]): Promise<{ status: string; value?: T; reason?: any }[]> => {
    return Promise.all(
        promises.map(promise => {
            return promise
                .then(data => {
                    return { status: 'fulfilled', value: data }
                })
                .catch(error => {
                    return { status: 'rejected', reason: error }
                })
        })
    )
}
