import $axios from '~/lib/axios'

import { API_BASE_URL } from '~/constants/index'
import {
    AcceptOnboardingTermsResponse,
    APIResponse,
    CheckOnboardingTermsStatusData,
    CheckOnboardingTermsStatusResponse,
    OnboardingTermsData,
    OnboardingTermsResponse
} from '~/types'
import { getErrorMessage } from '~/utils/api'

/**
 * Get terms and conditions status
 * @param onboardingId
 * @returns
 */
export const CheckOnboardingTermsStatus = async (onboardingId: string): Promise<CheckOnboardingTermsStatusResponse> => {
    const request: CheckOnboardingTermsStatusResponse = await $axios
        .get<CheckOnboardingTermsStatusData & APIResponse>(`${API_BASE_URL}/onboarding/TNCAccepted/${onboardingId}`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data.tncAccepted
                }
            }

            return {
                data: res.data.tncAccepted,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}

export const OnboardingTermsReviewDataDefault: OnboardingTermsData = {
    terms: []
}

/**
 * Get terms and conditions text data
 * @returns
 */
export const GetOnboardingTermsReviewData = async (): Promise<OnboardingTermsResponse> => {
    const request: OnboardingTermsResponse = await $axios
        .get<OnboardingTermsData & APIResponse>(API_BASE_URL + `/onboarding/terms`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: {
                        terms: res.data.terms
                    }
                }
            }

            return {
                data: { ...OnboardingTermsReviewDataDefault },
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<OnboardingTermsData>({ ...OnboardingTermsReviewDataDefault }, e)
        })

    return request
}

/**
 * Accept onboarding terms and conditions
 * @param onboardingId
 * @returns
 */
export const AcceptOnboardingTerms = async (onboardingId: string): Promise<AcceptOnboardingTermsResponse> => {
    const data = {}
    const request: AcceptOnboardingTermsResponse = await $axios
        .patch<APIResponse>(`${API_BASE_URL}/onboarding/acceptTNC/${onboardingId}`, data)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: true
                }
            }

            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}
