import * as Sentry from '@sentry/browser'
import { getUserId } from './auth'

const setUser = () => {
    const userId = getUserId()
    if (!!userId) Sentry.setUser({ id: userId })
}

const clearUser = () => {
    Sentry.setUser(null)
}

const $sentry = {
    setUser,
    clearUser
}

export default $sentry
