import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { APIResponse, Customer, GetCustomerDetailResponse, UpdateCustomerParams, UpdateCustomerResponse } from '~/types'
import { getErrorMessage } from '~/utils/api'

export const CustomerDetailDefaultData: Customer = {
    id: '',
    storeCustId: '',
    storeId: '',
    userId: '',
    name: '',
    address: '',
    addrArea: '',
    addrProvince: '',
    addrCity: '',
    addrSuburb: '',
    addrStreet: '',
    phone: ''
}

export const GetCustomerDetail = async (storeCustomerId: string): Promise<GetCustomerDetailResponse> => {
    const request: GetCustomerDetailResponse = await $axios
        .get<APIResponse & Customer>(`${API_BASE_URL}/customers/details/${storeCustomerId}`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: { ...CustomerDetailDefaultData },
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage(CustomerDetailDefaultData, e)
        })

    return request
}

export const UpdateCustomer = async (params: UpdateCustomerParams, storeId: string): Promise<UpdateCustomerResponse> => {
    const request: UpdateCustomerResponse = await $axios
        .put<APIResponse>(`${API_BASE_URL}/customers`, params, { params: { storeId } })
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return { data: true }
            }

            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })

    return request
}
