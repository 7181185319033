import { OrderDeliveryType, OrderStatus, OrderStatusForChipsFilter, OrderStatusInfo } from '~/types/order'

export const OrderStatusData: {
    status: OrderStatus
    icon: string
    text: string
    textStyle: string
}[] = [
    {
        status: '',
        icon: '',
        text: 'Semua',
        textStyle: ''
    },
    {
        status: 'PENDING',
        icon: '/icons/orders/status-pending.svg',
        text: 'Menunggu Pembayaran',
        textStyle: 'text-yellow'
    },
    {
        status: 'ACCEPTED',
        icon: '/icons/orders/status-accepted.svg',
        text: 'Pesanan Diterima',
        textStyle: 'text-blue-80'
    },
    {
        status: 'IN_PROCESS',
        icon: '/icons/orders/status-in-process.svg',
        text: 'Pesanan Diproses',
        textStyle: 'text-orange'
    },
    {
        status: 'READY_TO_PICKUP',
        icon: '/icons/orders/status-ready-to-pickup.svg',
        text: 'Siap Diambil',
        textStyle: 'text-blue-80'
    },
    {
        status: 'IN_DELIVERY',
        icon: '/icons/orders/status-in-delivery.svg',
        text: 'Sedang Dikirim',
        textStyle: 'text-blue-80'
    },
    {
        status: 'IN_DELIVERY_SELLER',
        icon: '/icons/orders/status-in-delivery.svg',
        text: 'Sedang Diantar',
        textStyle: 'text-blue-80'
    },
    {
        status: 'IN_DELIVERY_DIGITAL',
        icon: '/icons/orders/status-digital-send.svg',
        text: 'Kirim Digital',
        textStyle: 'text-blue-80'
    },
    {
        status: 'DELIVERED',
        icon: '/icons/orders/status-delivered.svg',
        text: 'Diterima Pembeli',
        textStyle: 'text-blue-80'
    },
    {
        status: 'COMPLETED',
        icon: '/icons/orders/status-delivered.svg',
        text: 'Selesai',
        textStyle: 'text-green-80'
    },
    {
        status: 'REJECTED',
        icon: '/icons/orders/status-rejected.svg',
        text: 'Ditolak',
        textStyle: 'text-red-80'
    },
    {
        status: 'CANCELED',
        icon: '/icons/orders/status-canceled.svg',
        text: 'Dibatalkan',
        textStyle: 'text-red-80'
    }
]

export const GetOrderStatus = (status: string) => {
    let statusData: OrderStatusInfo
    switch (status) {
        case 'PENDING':
            statusData = {
                status: 'Menunggu Pembayaran',
                type: 'warning'
            }
            break
        case 'ACCEPTED':
            statusData = {
                status: 'Pesanan Diterima',
                type: 'information'
            }
            break
        case 'IN_PROCESS':
            statusData = {
                status: 'Pesanan Diproses',
                type: 'warning'
            }
            break
        case 'READY_TO_PICKUP':
            statusData = {
                status: 'Siap Diambil',
                type: 'information'
            }
            break
        case 'IN_DELIVERY':
            statusData = {
                status: 'Sedang Dikirim',
                type: 'information'
            }
            break
        case 'IN_DELIVERY_SELLER':
            statusData = {
                status: 'Sedang Diantar',
                type: 'information'
            }
            break
        case 'IN_DELIVERY_DIGITAL':
            statusData = {
                status: 'Kirim Digital',
                type: 'information'
            }
            break
        case 'DELIVERED':
            statusData = {
                status: 'Diterima Pembeli',
                type: 'information'
            }
            break
        case 'COMPLETED':
            statusData = {
                status: 'Selesai',
                type: 'success'
            }
            break
        case 'REJECTED':
            statusData = {
                status: 'Ditolak',
                type: 'error'
            }
            break
        case 'CANCELED':
            statusData = {
                status: 'Dibatalkan',
                type: 'error'
            }
            break
        default:
            statusData = {
                status: '',
                type: 'information'
            }
    }
    return statusData
}

export const OrderStatusDataForChipsFilter: {
    status: OrderStatusForChipsFilter
    text: string
}[] = [
    {
        status: '',
        text: 'Semua'
    },
    {
        status: 'NEW_ORDERS',
        text: 'Pesanan Baru'
    },
    {
        status: 'SENT_ORDERS',
        text: 'Dikirim'
    },
    {
        status: 'FINISHED_ORDERS',
        text: 'Selesai'
    },
    {
        status: 'CANCELED',
        text: 'Batal'
    }
]

export const OrderDeliveryTypeData: {
    type: OrderDeliveryType
    icon: string
    text: string
}[] = [
    {
        type: 'DELIVERY',
        icon: '/icons/orders/type-delivery.svg',
        text: 'Kirim ke Lokasi'
    },
    {
        type: 'PICK_UP',
        icon: '/icons/orders/type-pickup.svg',
        text: 'Ambil Sendiri'
    },
    {
        type: 'SELF_DELIVERY',
        icon: '/icons/orders/type-seller-courier.svg',
        text: 'Kurir Penjual'
    },
    {
        type: 'DIGITAL',
        icon: '/icons/orders/type-digital.svg',
        text: 'Kirim Digital'
    }
]

export const OrderPaymentMethod = {
    COD: 'Tunai',
    TOP: 'Bayar Tempo',
    BNPL: 'Talangin Dulu',
    PAYMENT: 'Transfer Bank'
}

export const OrderDeliveryVariants = {
    DELIVERY: 'Kirim ke Lokasi',
    PICK_UP: 'Ambil Sendiri',
    SELF_DELIVERY: 'Kurir Penjual',
    DIGITAL: 'Kirim Digital'
}

export const OrderSource = {
    POS: 'Catat Manual',
    BUYERWEB: 'Checkout Toko'
}
