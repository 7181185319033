import onboarding from './bnpl-process'
import common from './common'
import customer from './customer'
import inventory from './inventory'
import invoice from './invoice'
import order from './order'
import product from './product'
import login from './login'

export default {
    common,
    order,
    product,
    onboarding,
    customer,
    inventory,
    invoice,
    login
}
