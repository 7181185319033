import { Icon } from '@bukuwarung/sachet'
import { routerTo } from '~/utils/page'

const navMenu: {
    text: string
    slug: string
    icon: JSX.Element
    submenu?: {
        text: string
        slug: string
        link?: (userId: string, userPhone: string) => string
    }[]
}[] = [
    {
        text: 'Beranda',
        slug: '/',
        icon: <Icon name="home-outline" size="xl"></Icon>
    },
    {
        text: 'Produk',
        slug: routerTo.product.url(),
        icon: <Icon name="archive-outline" size="xl"></Icon>
    },
    {
        text: 'Pemasukan',
        slug: routerTo.income.url(),
        icon: <Icon name="income-outline" size="xl"></Icon>,
        submenu: [
            {
                slug: routerTo.invoice.url(),
                text: 'Invoice Pemasukan'
            },
            {
                slug: routerTo.order.url(),
                text: 'Pesanan Online'
            },
            {
                slug: routerTo.customer.url(),
                text: 'Pelanggan'
            }
        ]
    },
    {
        text: 'Pengeluaran',
        slug: routerTo.expense.url(),
        icon: <Icon name="expense-outline" size="xl"></Icon>,
        submenu: [
            {
                slug: routerTo.payableInvoice.url(),
                text: 'Invoice Pengeluaran'
            },
            {
                slug: routerTo.supplier.url(),
                text: 'Vendor'
            }
        ]
    },
    // {
    //     text: 'Modal Supplier',
    //     slug: '/modal-supplier',
    //     icon: <Icon name="chat-outline" size="xl"></Icon>,
    //     submenu: [
    //         {
    //             slug: '/modal-supplier/pengajuan',
    //             text: 'Pengajuan',
    //             link: (userId, userPhone) => `/modal-supplier/pengajuan?user_id=${userId}&phone=${userPhone}&filter=${Number(IS_PROD)}`
    //         },
    //         {
    //             slug: '/modal-supplier/pencairan',
    //             text: 'Pencairan',
    //             link: (userId, userPhone) => `/modal-supplier/pencairan?user_id=${userId}&phone=${userPhone}&filter=${Number(IS_PROD)}`
    //         }
    //     ]
    // },
    {
        text: 'Akun',
        slug: routerTo.account.url(),
        icon: <Icon name="user-circle-outline" size="xl"></Icon>
    }
]

export default navMenu
