import { Dispatch, FC, SetStateAction, createContext, useContext, useState } from 'react'
import { SnackbarDefaultValue, SnackbarItem, SnackbarProps } from '~/components/Layout/Dashboard/Snackbar'
import { PopupDefaultValue, PopupProps } from '~/components/Popup/Popup'
import { IS_PROD } from '~/constants'
import { GetKycKybDetail } from '~/data'
import { GetInvoiceInfo } from '~/data/invoice/details'
import { EnvironmentVariablesDefault, EnvironmentVariablesI } from '~/types/env'
import { InvoiceApiParams } from '~/types/invoice'
import { StoreDetail } from '~/types/store'
import { groupCheck, splitPhoneNumber } from '~/utils/common'
import LocalStore, { LocalStoreKey } from '~/utils/local-store'

export interface AppCustomProps {
    ENV?: EnvironmentVariablesI
    appNonce?: string
    appXsrfToken?: string
}

export interface InitialData {
    redirect?: string
    store?: StoreDetail
    loadPage?: boolean
    deviceWidth?: number
}

export type GlobalData = InitialData & {
    popup: PopupProps
    snackbar: SnackbarProps
    ENV: EnvironmentVariablesI
    appNonce: string
    onboardChecks?: Record<'isStoreDone' | 'isKycDone' | 'isInvoiceCreated' | 'isPaymentCompleted', boolean>
    showOnlineStore?: boolean
    setStore: Dispatch<SetStateAction<StoreDetail>>
    localStore?: LocalStore<LocalStoreKey>
    onboardCheck: (data: StoreDetail) => Promise<void>
    setLocalStore: Dispatch<SetStateAction<LocalStore<'ONBOARDING_CLOSED'> | undefined>>
    setShowOnlineStore: Dispatch<SetStateAction<boolean>>
    setLoadPage: Dispatch<SetStateAction<boolean>>
}

export const InitialDataContext = createContext<GlobalData>({
    popup: {
        ...PopupDefaultValue
    },
    snackbar: { ...SnackbarDefaultValue },
    ENV: { ...EnvironmentVariablesDefault },
    appNonce: '',
    onboardChecks: {
        isStoreDone: false,
        isKycDone: false,
        isInvoiceCreated: false,
        isPaymentCompleted: false
    },
    showOnlineStore: true,
    setStore: () => false,
    onboardCheck: async () => void null,
    setLocalStore: () => false,
    setShowOnlineStore: () => false,
    setLoadPage: () => false
})

export const useGlobal = () => useContext(InitialDataContext)

const baseURL = () => {
    return IS_PROD ? '*.tokoko.id tokoko.id' : '*.tokowa.co *'
}

export const getCSP = (nonce: string) => {
    const CSP = [
        `connect-src 'self' ${baseURL()} *.bukuwarung.com *.sentry.io *.googleapis.com *.amplitude.com *.tally.so tally.so posthog.com *.posthog.com salesiq.zoho.com *.zohopublic.com wss://vts.zohopublic.com;`, // API call
        `script-src 'self' *.googleapis.com ${
            IS_PROD ? '' : "'unsafe-eval'"
        } ${baseURL()} *.clevertap-prod.com tally.so 'nonce-${nonce}' posthog.com *.posthog.com js.zohocdn.com js.zohostatic.com salesiq.zohopublic.com static.zohocdn.com;`, // scripts
        `style-src 'self' ${baseURL()} 'unsafe-inline' data: css.zohocdn.com css.zohostatic.com;`, // styles
        `font-src 'self' fonts.gstatic.com css.zohocdn.com;`, // fonts
        `img-src 'self' ${baseURL()} *.amazonaws.com *.googleapis.com tally.so data: blob: css.zohocdn.com salesiq.zohopublic.com files.zohopublic.com;` // images
    ]

    return CSP.join('')
}

interface GlobalProps {
    initialData: GlobalData
    deviceWidth: number
    store: StoreDetail
    setLoadPage: Dispatch<SetStateAction<boolean>>
    loadPage: boolean
    children?: JSX.Element
}

const GlobalProvider: FC<GlobalProps> = ({ initialData, deviceWidth, store, children, setLoadPage, loadPage }) => {
    const [localStore, setLocalStore] = useState<LocalStore<LocalStoreKey>>()
    const [showOnlineStore, setShowOnlineStore] = useState(true)
    const [popupChildren, setPopchildren] = useState(<></>)
    const [showPopup, setShowPopup] = useState(false)
    const [onboardChecks, setOnboardChecks] = useState<
        Record<'isStoreDone' | 'isKycDone' | 'isInvoiceCreated' | 'isPaymentCompleted', boolean>
    >({
        isStoreDone: true,
        isKycDone: false,
        isInvoiceCreated: false,
        isPaymentCompleted: false
    })
    const [snackbar, setSnackbar] = useState<SnackbarItem>({
        value: false,
        style: 'primary',
        message: ''
    })

    const [snackbarTimeout, setSnackbarTimeout] = useState<ReturnType<typeof setTimeout> | undefined>()

    const popupHandle = (value: boolean, children: JSX.Element, onClose?: () => void) => {
        setPopchildren(children)
        setShowPopup(value)

        if (value) {
            return
        }

        if (onClose) {
            onClose()
        }
    }

    const closePopup = (onClose?: () => void) => {
        popupHandle(false, <></>, onClose)
    }

    const snackbarHandle = (item: SnackbarItem, onClose?: () => void) => {
        setSnackbar(item)

        if (snackbarTimeout) {
            clearTimeout(snackbarTimeout)
        }

        if (item.value && !item.noTimeOut) {
            setSnackbarTimeout(
                setTimeout(() => {
                    snackbarHandle({ value: false, style: 'primary', message: '' })
                    if (onClose) {
                        onClose()
                    }
                }, 3000)
            )

            return
        }

        if (onClose) {
            onClose()
        }
    }

    const onboardCheck = async (data: StoreDetail) => {
        const onboardChecks: GlobalData['onboardChecks'] = {
            isStoreDone: true,
            isKycDone: false,
            isInvoiceCreated: false,
            isPaymentCompleted: false
        }

        const { data: kycData } = await GetKycKybDetail(splitPhoneNumber(data?.phone || '')?.phoneNumber || '')

        if (!groupCheck(kycData?.basic_kyc?.kyc_tier, ['ADVANCED', 'SUPREME'])) {
            setOnboardChecks(onboardChecks)
            return
        }

        onboardChecks.isKycDone = true

        const params: InvoiceApiParams = {
            pageSize: 1,
            pageNo: 0,
            invoicePayable: true
        }

        const { data: payableInvoiceData } = await GetInvoiceInfo(params)
        onboardChecks.isInvoiceCreated = !!payableInvoiceData.length

        if (onboardChecks.isInvoiceCreated) {
            const { data: paidInvoiceData } = await GetInvoiceInfo({ ...params, status: 'PAID', paymentType: 'DIGITAL' })
            onboardChecks.isPaymentCompleted = !!paidInvoiceData.length
        }

        setOnboardChecks(onboardChecks)
    }

    const initialDataValue: GlobalData = {
        ...initialData,
        loadPage,
        deviceWidth,
        popup: {
            show: showPopup,
            children: popupChildren,
            popupHandle,
            closePopup
        },
        snackbar: {
            item: snackbar,
            snackbarHandle
        },
        store,
        onboardChecks,
        showOnlineStore,
        localStore,
        onboardCheck,
        setLocalStore,
        setShowOnlineStore,
        setLoadPage
    }

    return <InitialDataContext.Provider value={initialDataValue}>{children}</InitialDataContext.Provider>
}
export default GlobalProvider
