import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { Identity, IdentityFormField, IdentityFormFieldError } from '~/types/bnpl-process/identity'

export const IdentityFormFieldDefaultValue: IdentityFormField = {
    id: '',
    companyType: 'INDIVIDUAL',
    companyName: '',
    name: '',
    phoneNumber: 0,
    ktpNumber: 0,
    idCardDocument: '',
    birthPlace: '',
    birthDate: '',
    date: '01',
    month: '01',
    year: '1990',
    gender: 'male',
    maritalStatus: 'bachelor',
    onboardingType: 'SUPPLIER',
    businessType: [],
    businessTypeText: '',
    salesTeamId: '',
    uploadedKtpData: '',
    photoKtp: '',
    selfiKtp: '',
    isGetKycData: false,
    isKycVerified: false
}

export const IdentityFormFieldErrorDefaultValue: IdentityFormFieldError = {
    id: '',
    companyType: '',
    companyName: '',
    name: '',
    phoneNumber: '',
    ktpNumber: '',
    idCardDocument: '',
    birthPlace: '',
    birthDate: '',
    date: '',
    month: '',
    year: '',
    gender: '',
    onboardingType: '',
    maritalStatus: '',
    businessType: '',
    businessTypeText: '',
    salesTeamId: '',
    uploadedKtpData: '',
    photoKtp: '',
    selfiKtp: '',
    isGetKycData: '',
    isKycVerified: false
}

export const IdentityFormCompanyTypes = [
    {
        text: 'Perseorangan',
        field: 'companyType',
        type: 'INDIVIDUAL'
    },
    {
        text: 'PT/CV',
        field: 'companyType',
        type: 'COMPANY'
    }
]

export const IdentityFormCompanyTypesHeading = 'Tipe Perusahaan'

export const IdentityFormGenders = [
    {
        text: 'Laki-laki',
        field: 'gender',
        type: 'male'
    },
    {
        text: 'Perempuan',
        field: 'gender',
        type: 'female'
    }
]

export const IdentityFormGenderHeading = 'Jenis Kelamin'

export const IdentityFormMaritalTypes = [
    {
        text: 'Kawin',
        field: 'maritalStatus',
        type: 'married'
    },
    {
        text: 'Belum Kawin',
        field: 'maritalStatus',
        type: 'bachelor'
    },
    {
        text: 'Cerai',
        field: 'maritalStatus',
        type: 'divorced'
    }
]

export const IdentityFormMaritalHeading = 'Status Perkawinan'

export const getBusinessTypesData = async () => {
    const businessTypes = await $axios
        .get(API_BASE_URL + `/onboarding/business-types`)
        .then(res => {
            if (res.status === 200) {
                const data: any = []
                let otherCat = {}
                res.data.map((value: any) => {
                    if (parseInt(value.id) === 13) {
                        otherCat = {
                            value: value.name,
                            text: value.name,
                            icon: value.image
                        }
                    } else {
                        data.push({
                            value: value.name,
                            text: value.name,
                            icon: value.image
                        })
                    }
                })

                data.sort((a: any, b: any) => parseInt(a.value) - parseInt(b.value))
                data.push(otherCat)
                return data
            }
            return {}
        })
        .catch(() => {
            return {}
        })

    return businessTypes
}

export const getBirthPlaces = () => {
    const birthPlaces = [
        {
            value: 'Jakarta',
            text: 'Jakarta'
        },
        {
            value: 'Jaipur',
            text: 'Jaipur'
        },
        {
            value: 'Bengluru',
            text: 'Bengluru'
        }
    ]

    return birthPlaces
}

export const getMonths = () => {
    const birthPlaces = [
        {
            value: '01',
            text: 'Jan'
        },
        {
            value: '02',
            text: 'Feb'
        },
        {
            value: '03',
            text: 'Mar'
        },
        {
            value: '04',
            text: 'Apr'
        },
        {
            value: '05',
            text: 'May'
        },
        {
            value: '06',
            text: 'Jun'
        },
        {
            value: '07',
            text: 'Jul'
        },
        {
            value: '08',
            text: 'Aug'
        },
        {
            value: '09',
            text: 'Sep'
        },
        {
            value: '10',
            text: 'Oct'
        },
        {
            value: '11',
            text: 'Nov'
        },
        {
            value: '12',
            text: 'Dec'
        }
    ]

    return birthPlaces
}

export const daysInMonth = (year: any, month: any) => {
    const day = new Date(Number(year), Number(month), 0).getDate()
    const dayArr = []
    for (let i = 1; i <= day; i++) {
        const dayObj = {
            value: i < 10 ? `0${i}` : String(i),
            text: String(i)
        }
        dayArr.push(dayObj)
    }

    return dayArr
}

export const getYears = () => {
    const date = new Date()
    const year = date.getFullYear()
    const k = 1950
    const yearArr = []
    for (let i = year; i >= k; i--) {
        const yearObj = {
            value: String(i),
            text: String(i)
        }
        yearArr.push(yearObj)
    }
    return yearArr
}

export const getIdentityData = async (onBoardingId: string) => {
    const { data, status }: { data?: Identity; status: boolean } = await $axios
        .get(API_BASE_URL + `/onboarding/identity/${onBoardingId}`)
        .then(res => {
            if (res.status === 200) {
                return { data: res.data, status: true }
            }
            return { status: false }
        })
        .catch(() => {
            return { status: false }
        })

    const res = {
        data,
        status
    }
    return res
}

export const getIdentityKtp = async (onBoardingId: string) => {
    const data = await $axios
        .get(API_BASE_URL + `/onboarding/image/${onBoardingId}/KTP`)
        .then(res => {
            if (res.status === 200) {
                return res.data
            }
            return {}
        })
        .catch(() => {
            return {}
        })

    return data
}

export const getCitiesData = async () => {
    const data = await $axios
        .get(API_BASE_URL + `/onboarding/cities/`)
        .then(res => {
            if (res.status === 200) {
                return res.data
            }
            return {}
        })
        .catch(() => {
            return {}
        })
    return data
}

export const getSalesTeamData = async () => {
    const data = await $axios
        .get(API_BASE_URL + `/stores/abcd/experiments/SALES_TEAMS`)
        .then(res => {
            if (res.status === 200) {
                const data: any = []
                res.data.value.map((value: any) => {
                    data.push({
                        value: value.id,
                        text: value.name
                    })
                })
                return data
            }
            return []
        })
        .catch(() => {
            return []
        })
    return data
}

export const TEXTUAL_DATA: any = {
    FORM_FIELD_ERRORS: {
        companyType: 'Tipe Perusahaan wajib diisi',
        companyName: 'Nama Perusahaan wajib diisi',
        name: 'Masukkan Nama wajib diisi',
        ktpNumber: 'NIK wajib diisi',
        idCardDocument: 'KTP wajib diisi',
        birthPlace: 'Tempat Lahir wajib diisi',
        birthDate: 'Tempat Lahir wajib diisi',
        gender: 'Jenis Kelamin wajib diisi',
        maritalStatus: 'Status Perkawinan wajib diisi',
        businessType: 'Tipe Bisnis wajib diisi',
        salesTeamId: 'ID Tim Sales wajib diisi'
    },
    FORM_SUBMIT_ERROR: 'Terjadi kesalahan, silahkan coba lagi',
    FORM_SUBMIT_SUCCESS: 'Data Toko anda berhasil disimpan!'
}
