import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import locale from '~/locale'

import { Area, GetAreasParams, GetAreasReponse } from '~/types'
import { $error } from '~/utils/log'

export const GetAreas = async (params: GetAreasParams): Promise<GetAreasReponse> => {
    const request: GetAreasReponse = await $axios
        .get<Area[]>(`${API_BASE_URL}/shipper/v1/maps/areas`, {
            params
        })
        .then(res => {
            if (res.status === 200) {
                return { data: res.data }
            }

            return {
                data: [],
                error: {
                    field: 'general',
                    message: locale.id.common.request_error
                }
            }
        })
        .catch(e => {
            $error(e)
            return {
                data: [],
                error: {
                    field: 'general',
                    message: locale.id.common.request_error
                }
            }
        })

    return request
}
