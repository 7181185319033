var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2.3.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const IS_PROD = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'

Sentry.init({
    dsn: SENTRY_DSN,
    sampleRate: IS_PROD ? 1 : 0,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: IS_PROD ? 0.5 : 0,
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    debug: !IS_PROD
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
})
