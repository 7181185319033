import $axios from '~/lib/axios'

import { API_BASE_URL } from '~/constants/index'
import { APIResponse, OnboardingStatus, UpdateOnboardingStatusResponse } from '~/types'
import { getErrorMessage } from '~/utils/api'

/**
 * Update onboarding status
 * @param onboardingId
 * @param status
 * @returns
 */
export const UpdateOnboardingStatus = async (onboardingId: string, status: OnboardingStatus): Promise<UpdateOnboardingStatusResponse> => {
    const data = {}
    const request: UpdateOnboardingStatusResponse = await $axios
        .patch<APIResponse>(API_BASE_URL + `/onboarding/status/${onboardingId}/${status}`, data)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: true
                }
            }

            return {
                data: false,
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })

    return request
}
