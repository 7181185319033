import { IconsProps } from '~/types'

const IconInfo = ({ className }: IconsProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className={className} fill="none">
            <circle cx="8" cy="8" r="8" fill="#8D8D8D" />
            <rect x="6.66675" y="6.66663" width="2.66667" height="6.66667" rx="1.33333" fill="white" />
            <circle cx="8.00008" cy="3.99996" r="1.33333" fill="white" />
        </svg>
    )
}

export default IconInfo
