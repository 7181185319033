import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { AddressBankFormField, AddressBankFormFieldError, BanksResponse, selectedBankDataType } from '~/types/bnpl-process/addressBank'

export const AddressBankFormFieldDefaultValue: AddressBankFormField = {
    id: '',
    addressLine: '',
    bankName: '',
    bankAccountNumber: 0,
    bankAccountHolderName: '',
    postalInfo: {
        addressArea: '',
        addressStreet: '',
        addressProvince: '',
        addressCity: '',
        addressSuburb: '',
        completeAddress: {
            city: '',
            district: '',
            id: '',
            name: '',
            postalCode: '',
            province: '',
            subdistrict: ''
        }
    },
    valid: false
}

export const AddressBankFormFieldErrorDefaultValue: AddressBankFormFieldError = {
    id: '',
    addressLine: '',
    bankName: '',
    bankAccountNumber: '',
    bankAccountHolderName: '',
    postalInfo: '',
    valid: ''
}

export const ACCOUNT_INFORMATION_BANK_DETAILS_VERIFIED_LABEL = 'Rekening Terverifikasi'

export const ACCOUNT_INFORMATION_BANK_DETAILS_NON_VERIFIED_LABEL = 'Rekening Tidak Terverifikasi'

export const ACCOUNT_INFORMATION_BANK_DETAILS_DELETE_LABEL = 'Hapus'

export const getBankData = async () => {
    const data: BanksResponse = await $axios
        .get(API_BASE_URL + '/payments/banks/disbursements')
        .then(res => {
            if (res.status === 200) {
                const _data: any = []
                res.data.map((value: BanksResponse) => {
                    const _dataValue = {
                        value: value.bank_code,
                        text: value.name,
                        icon: value.logo
                    }
                    _data.push(_dataValue)
                })
                return _data
            }

            return []
        })
        .catch(() => {
            return []
        })

    return data
}

export const selectedBankData: selectedBankDataType = {
    value: '',
    text: '',
    icon: '',
    accountHolderName: '',
    accountId: '',
    accountNumber: '',
    bankCode: '',
    valid: null
}

export const getAddressBankData = async (onBoardingId: string) => {
    const { data, status }: { data?: any; status: boolean } = await $axios
        .get(API_BASE_URL + `/onboarding/addressAndBank/${onBoardingId}`)
        .then(res => {
            if (res.status === 200) {
                return { data: res.data, status: true }
            }
            return { status: false }
        })
        .catch(() => {
            return { status: false }
        })
    const res = {
        data,
        status
    }
    return res
}
