export default {
    header: {
        store_name_default: 'Toko Anda'
    },
    request_error: 'Terjadi kesalahan. Silakan coba lagi!',
    response_error: 'Maaf atas ketidaknyamanan yang terjadi pada Anda',
    no_more_records: 'Tidak ada catatan lagi yang ditemukan',
    FORM_SUBMIT_SUCCESS: 'Data Toko anda berhasil disimpan!',
    session_timeout: 'Sesi Anda telah kedaluwarsa, jadi silakan login dan coba lagi.',
    payment_method: {
        top: 'Bayar Tempo'
    },
    all: 'Semua',
    error: {
        title: 'Terjadi Kesalahan!',
        general: 'Silahkan coba lagi.',
        not_found: 'Halaman yang kunjungi tidak ditemukan.'
    }
}
