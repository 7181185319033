import { Button, COLORS, Icon, Modal } from '@bukuwarung/sachet'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useGlobal } from '~/components/Global/GlobalProvider'
import { routerTo } from '~/utils/page'
import { PaymentActivationStatus } from '../payable-Invoice/payable-invoice-dashboard/payable-invoice-details/PaymentActivationStatus'

interface OnboardingPopupProps {
    onboardProgress: number
    show: boolean
    setShow: (value: boolean) => void
}

const onboardSteps = [
    {
        title: 'Daftar Toko',
        description: 'Tambahkan detail usaha Anda'
    },
    {
        title: 'Verifikasi Akun',
        description: 'Siapkan data KTP Anda'
    },
    {
        title: 'Buat Invoice dan Pembayaran',
        description: 'Buat Invoice pertama Anda sekaligus lakukan pembayaran'
    },
    {
        title: 'Selesaikan Pembayaran Online',
        description: 'Gunakan Kartu Kredit untuk bayar ke rekening vendor secara online'
    }
]

export const OnboardingPopup = ({ onboardProgress, show, setShow }: OnboardingPopupProps) => {
    const router = useRouter()
    const { popup } = useGlobal()
    const nextStep = onboardProgress + 1

    const onClose = () => {
        setShow(false)
    }

    const handleNextButton = (step: number) => {
        if (nextStep !== step) {
            return
        }

        switch (step) {
            case 1:
                onClose()
                break
            case 2:
                popup.popupHandle(true, <PaymentActivationStatus open={true} onClose={() => popup.closePopup()}></PaymentActivationStatus>)
                break
            case 3:
                router.push(routerTo.payableInvoiceAdd.url(true))
                onClose()
                break
            case 4:
                router.push(routerTo.payableInvoice.url())
                onClose()
                break
            default:
                onClose()
                break
        }
    }

    const renderIcon = (step: number) => {
        if (step === nextStep) {
            return <Icon name="chevron-right" color={COLORS.MAIN.PRIMARY[500]} size="xl"></Icon>
        }

        if (step < nextStep) {
            return <Icon name="check-circle" color={COLORS.SEMANTIC.GREEN[400]} size="xl"></Icon>
        }

        return <></>
    }

    return (
        <Modal show={show} size="md" title="Yuk, buat transaksi pertama Anda!" onClose={onClose} close removeContentPadding>
            <div className="w-full overflow-auto p-6">
                <div className="mb-5 flex w-full items-center">
                    <div className="mr-2.5 flex h-3 flex-grow items-stretch rounded-3xl bg-neutrals-200 bg-opacity-70 p-0.5">
                        <div style={{ width: `${(onboardProgress / 4) * 100}%` }} className={`rounded-lg bg-green-400`}></div>
                    </div>
                    <p className="flex-shrink-0 text-neutrals-500">
                        <b className="text-green-400">{onboardProgress}</b>/4 Selesai
                    </p>
                </div>
                <div className="mb-5 grid w-full grid-cols-1">
                    {onboardSteps.map((step, key) => {
                        const isCurrentStep = key === onboardProgress
                        return (
                            <div
                                key={key}
                                className={`flex cursor-pointer items-start border-b border-neutrals-200 py-4 hover:bg-neutrals-50`}
                                role="button"
                                onClick={() => handleNextButton(key + 1)}>
                                <div className="mr-2 flex-shrink-0">
                                    <div
                                        className={`flex h-10 w-10 items-center justify-center rounded-full ${
                                            !isCurrentStep
                                                ? 'bg-neutrals-200 bg-opacity-70 text-neutrals-500'
                                                : 'bg-primary-50 bg-opacity-50 text-primary-500'
                                        } p-2.5`}>
                                        <p className="font-bold">{key + 1}</p>
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <h3 className={`text-sm font-bold ${isCurrentStep ? 'text-primary-500' : 'text-neutrals-900'}`}>
                                        {step.title}
                                    </h3>
                                    <p className="text-xs text-neutrals-500">{step.description}</p>
                                </div>
                                <div className="flex flex-shrink-0 items-center justify-center self-center">{renderIcon(key + 1)}</div>
                            </div>
                        )
                    })}
                </div>

                <a
                    className="mb-12 flex w-full max-w-[360px] items-start rounded-2xl bg-primary-50 bg-opacity-50 p-4 no-underline"
                    role="button"
                    href={'https://youtu.be/6kJXX3h8d7k?si=cQxsFVD2K0th2aEQ'}
                    target="_blank"
                    rel="noreferrer">
                    <div className="mr-3 flex-shrink-0 rounded-lg bg-neutrals-50">
                        <Image src={'/images/onboarding/thumbnail.png'} width={96} height={58}></Image>
                    </div>
                    <div className="flex-grow">
                        <h3 className="mb-1 text-sm font-bold text-neutrals-900">Kenalan dengan Tokokopay</h3>
                        <p className="text-2xs leading-normal text-neutrals-600">
                            Solusi pembayaran usaha dengan Kartu Kredit tanpa mesin EDC
                        </p>
                    </div>
                </a>

                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <div></div>
                    <Button
                        onClick={() => handleNextButton(nextStep)}
                        text={onboardProgress === 4 ? 'Selesai' : `Lanjut ${onboardSteps[onboardProgress].title}`}></Button>
                </div>
            </div>
        </Modal>
    )
}
