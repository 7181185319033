import { Icon } from '@bukuwarung/sachet'
import { useContext } from 'react'

import { InitialDataContext } from '~/components/Global/GlobalProvider'

//import Icon from '~/components/Icon'
import IconCheckRound from '~/components/Icons/IconCheckRound'
import IconInfo from '~/components/Icons/IconInfo'
import IconInfoRed from '~/components/Icons/IconInfoRed'
import Spacer from '~/components/Spacer'

const SnackbarStyle = {
    info: 'bg-blue-100 text-blue-600',
    primary: 'bg-blue-10 text-blue-80',
    secondary: 'bg-black-10 text-neutrals-600',
    error: 'bg-red-10 text-red-80'
}

// const SnackbarCloseIcon = {
//   primary: '/icons/close-green.svg',
//   secondary: '/icons/close.svg',
//   error: '/icons/close-red.svg'
// }

export interface SnackbarItem {
    value: boolean
    style: keyof typeof SnackbarStyle
    message: string
    fixed?: boolean
    noTimeOut?: boolean
    width?: string
}

export interface SnackbarProps {
    item: SnackbarItem
    snackbarHandle: (item: SnackbarItem, onClose?: () => void) => void
}

export const SnackbarDefaultValue: SnackbarProps = {
    item: {
        value: false,
        style: 'primary',
        message: ''
    },
    snackbarHandle: () => false
}

const Snackbar = () => {
    const { snackbar } = useContext(InitialDataContext)

    const renderIcon = () => {
        if (snackbar.item.style === 'primary') {
            return <IconCheckRound className="mr-2.5 h-6 w-6"></IconCheckRound>
        }

        if (snackbar.item.style === 'error') {
            return <IconInfoRed className="mr-2.5 h-6 w-6"></IconInfoRed>
        }

        if (snackbar.item.style === 'info') {
            return (
                <div className="mr-2.5">
                    <Icon name="information-circle" color="#1A56DB" />
                </div>
            )
        }
        return <IconInfo className="mr-2.5 h-6 w-6"></IconInfo>
    }

    const renderSnackBarWithoutParent = () => {
        return <div className="fixed left-0 top-0 z-40 h-full w-full bg-black bg-opacity-40">{renderSnackBar()}</div>
    }

    const renderSnackBar = () => {
        return (
            <div
                className={`${snackbar.item.fixed ? 'fixed' : 'absolute'} z-50 flex items-start rounded-lg ${
                    snackbar.item.width ? snackbar.item.width : 'w-96'
                } left-1/2 max-w-full -translate-x-1/2 transform shadow-md transition-transform md-reverse:w-margined-full-width ${
                    SnackbarStyle[snackbar.item.style]
                } ${snackbar.item.value ? 'px-4 py-3 ' : 'translate-y-20 p-0'} bottom-6`}>
                <span className="flex-shrink-0 leading-none">{renderIcon()}</span>
                <span className="mr-2.5">{snackbar.item.message}</span>
                <Spacer></Spacer>
                {/* <Icon
          src={SnackbarCloseIcon[snackbar.item.style]}
          width={24}
          onClick={() =>
            snackbar.snackbarHandle({
              ...snackbar.item,
              value: false
            })
          }
        ></Icon> */}
            </div>
        )
    }

    return <>{snackbar.item.noTimeOut ? renderSnackBarWithoutParent() : renderSnackBar()}</>
}

export default Snackbar
