import { responsiveBreakpointMap } from '@bukuwarung/sachet'
import { STORAGE_WEBVIEW } from '~/constants'
import { groupCheck } from '~/utils/common'
import AuthLayout from './AuthLayout'
import Dashboard from './Dashboard/Dashboard'
import FullscreenCenterizedLayout from './FullscreenCenterizedLayout'
import InitialPageLoad from './InitialPageLoad'

interface LayoutWrapperProps {
    /**
     * Children component
     */
    children: any
    /**
     * Device width
     */
    deviceWidth: number
    /**
     * Page still loading
     */
    loadPage: boolean
    /**
     * Page pathname
     */
    pathname: string
    /**
     * Page query
     */
    query: any
}
export const LayoutWrapper = ({ children, deviceWidth, loadPage, pathname, query }: LayoutWrapperProps) => {
    // if device width or if page still loading return page load component
    if (!deviceWidth || loadPage) {
        return <InitialPageLoad></InitialPageLoad>
    }

    const parentRoute = pathname.split('/')[1]

    // For auth page use auth layout
    if (groupCheck(parentRoute, ['login'])) {
        return <AuthLayout>{children}</AuthLayout>
    }

    if (groupCheck(parentRoute, ['getting-started', 'under-maintenance'])) {
        return <FullscreenCenterizedLayout>{children}</FullscreenCenterizedLayout>
    }

    // Show mobile version only mobile supported pages
    const showMobileVersion = groupCheck(parentRoute, [
        '',
        'pemasukan',
        'daftar-talangindulu',
        'pengeluaran',
        'aktifasi-pembayaran',
        'complete-payment'
    ])

    const hideOnboarding = groupCheck(parentRoute, ['daftar-talangindulu', 'aktifasi-pembayaran', 'complete-payment'])

    // Hide navigation in case of analytics and supplier onboard related pages with screen size below 768 or bulk upload review page
    const hideNavigation =
        (groupCheck(parentRoute, ['daftar-talangindulu']) && deviceWidth < responsiveBreakpointMap.md) ||
        pathname === '/products/bulk-upload/review' ||
        pathname.startsWith('/complete-payment')

    const { platform } = query
    const isAndroidWebview = localStorage.getItem(STORAGE_WEBVIEW) === 'true'
    const showNavbar = !(platform === 'android' || isAndroidWebview)

    return (
        <Dashboard
            mobile={showMobileVersion}
            hideNavigation={hideNavigation || !showNavbar}
            showNavbar={showNavbar}
            pathname={pathname}
            hideOnboarding={hideOnboarding}
            isAndroidWebview={isAndroidWebview}>
            {children}
        </Dashboard>
    )
}
