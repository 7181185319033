import { API_BASE_URL, TOKOKO_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import { APIResponse, Bank, DataResponse, KYB_STATUS, KYC_CHECKS_STATUS, KYC_STATUS, VerifyBankResponse } from '~/types'
import { getErrorMessage } from '~/utils/api'

export * from './type'

export const KYC_KYB_STATUS_MAP = {
    success: 'Berhasil',
    warning: 'Dalam Proses',
    error: 'Gagal'
}

export const KYC_STEPS_STATUS_MAP: { [K in KYC_CHECKS_STATUS]: keyof typeof KYC_KYB_STATUS_MAP } = {
    CREATED: 'warning',
    FACE_MATCH_CREATED: 'warning',
    FACE_MATCH_DONE: 'warning',
    FACE_MATCH_FAILED: 'error',
    LIVENESS_FAILED: 'error',
    LIVENESS_SUCCESS: 'warning',
    LOOKUP_FAILED: 'error',
    LOOKUP_SUCCESS: 'warning',
    MANUALLY_REJECTED: 'error',
    MANUALLY_VERIFIED: 'success',
    OCR_CREATED: 'warning',
    OCR_DONE: 'warning',
    OCR_FAILED: 'error',
    PENDING_MANUAL_VERIFICATION: 'warning',
    PENDING_VERIFICATION: 'warning',
    REJECTED: 'error',
    VERIFIED: 'success'
}

export const KYC_VERIFIED_STATUSES: KYC_STATUS[] = ['MANUALLY_VERIFIED', 'VERIFIED']
export const KYC_PENDING_STATUSES: KYC_STATUS[] = ['PENDING', 'PENDING_MANUAL_VERIFICATION', 'PENDING_VERIFICATION', 'RESUBMISSION']
export const KYC_REJECTED_STATUSES: KYC_STATUS[] = ['REJECTED']
export const KYB_VERIFIED_STATUSES: KYB_STATUS[] = ['MANUALLY_VERIFIED', 'VERIFIED']
export const KYB_PENDING_STATUSES: KYB_STATUS[] = ['PENDING', 'PENDING_MANUAL_VERIFICATION', 'PENDING_VERIFICATION']
export const KYB_REJECTED_STATUSES: KYB_STATUS[] = ['REJECTED', 'MANUALLY_REJECTED']

export const GetBanks = async (): Promise<DataResponse<Bank[]>> => {
    const response = await $axios
        .get<Bank[]>(`${API_BASE_URL}/payments/banks/disbursements`)
        .then(res => ({ data: res.data }))
        .catch(e => getErrorMessage([], e))

    return response
}

export const VerifyBankAccounts = async (body: {
    storeId: string
    accountNumber: string
    bankCode: string
}): Promise<DataResponse<VerifyBankResponse>> => {
    const response = await $axios
        .post<APIResponse & VerifyBankResponse>(`${API_BASE_URL}/payments/bank_accounts/validate`, body)
        .then(res => ({ data: res.data }))
        .catch(e =>
            getErrorMessage<VerifyBankResponse>(
                {
                    accountHolderName: '',
                    accountId: '',
                    accountNumber: '',
                    bankCode: '',
                    valid: false
                },
                e
            )
        )

    return response
}

interface CheckoutRequest {
    storeId: string
    amount: number
    description?: string
    beneficiaryDetails: {
        paymentDestinationType: 'BANK'
        code: string
        number: string
        beneficiaryName: string
    }
    redirectUrl?: string
}

interface CheckoutResponse {
    paymentId: string
    currentStatus: 'PENDING' | 'SUCCESS' | 'FAILED'
    amount: number
    expiredAt: string
    checkoutPage: string
    beneficiaryDetails: {
        paymentDestinationType?: string
        code: string
        number: string
        beneficiaryName?: string
    }
}

export const InvoiceCheckout = async (
    paymentId: string,
    data: CheckoutRequest,
    feature: 'INVOICE'
): Promise<DataResponse<CheckoutResponse | undefined>> => {
    const response = await $axios
        .post(`${TOKOKO_API_BASE_URL}/api/v2/payment/checkout?paymentId=${paymentId}&feature=${feature}`, data)
        .then(res => ({
            data: res.data.data
        }))
        .catch(e => getErrorMessage(undefined, e))

    return response
}

type PaymentStatusMap<T extends string> = {
    paymentId: string
    currentStatus: T
    paymentAmount: number
    paymentType: 'TRANSACTION'
    ucLink: string
    ucLinkExpiryAt: string
    paymentProgress: Record<T, string>
    createdAt: string
    lastModifiedAt: string
}

export interface PaymentStatusDetail {
    transactionDetails?: PaymentStatusMap<'PENDING' | 'PAID' | 'FAILED' | 'EXPIRED'>
    disbursementDetails?: PaymentStatusMap<'PENDING' | 'COMPLETED' | 'FAILED'>
    refundDetails?: PaymentStatusMap<'REFUNDING' | 'REFUNDED' | 'REFUNDING FAILED'>
}

export const CheckPaymentStatus = async (
    paymentId: string,
    feature: 'Invoice' | 'Order'
): Promise<DataResponse<PaymentStatusDetail | undefined>> => {
    const response = await $axios
        .get(`${TOKOKO_API_BASE_URL}/api/v2/payment/status?paymentId=${paymentId}&feature=${feature}`)
        .then(res => ({
            data: res.data.data
        }))
        .catch(e => getErrorMessage(undefined, e))

    return response
}
