// STATIC
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT
export const IS_PROD = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
export const API_DEVICE_ID = '159f7123-ae79-43ff-b7a6-a83814e83793'
export const MAX_STOCK_UPDATE_AT_A_TIME = 20
export const TOKOKO_SELLER_WEB = 'tokoko-seller-web'
export const TOKOKO_SELLER_WEB_VERSION = '2.3.0'
// API
export const API_BASE_URL = '/next/api/tokoko'
export const INVOICE_API_BASE_URL = '/next/api/invoice'
export const USER_SERVICE_BASE_URL = '/next/api/auth'
export const TOKOKO_API_BASE_URL = '/next/api/tokoko/base'
export const PAYMENT_API_BASE_URL = '/next/api/payment'
// Cookies
export const SELLER_COOKIE_ACCESS_TOKEN = 'tokoko_at'
export const SELLER_COOKIE_PN = 'tokoko_pn'
export const SELLER_COOKIE_REFRESH_TOKEN = 'tokoko_ref_at'
// URL
export const BLOG_PAGE_URL = 'https://belajar.tokoko.id/ketentuan-pengiriman-di-tokoko'
export const GOOGLE_PLAY_APP_URL = 'https://play.google.com/store/apps/details?id=com.tokoko.and'
// Storage
export const STORAGE_WEBVIEW = 'tokoko_webview_' + ENVIRONMENT
export const AUTH_USER_ID_STORAGE_NAME = 'auth_user_id_v2_' + ENVIRONMENT
export const INVENTORY_FLAG_STORAGE_NAME = 'tokoko_inventory_enabled_' + ENVIRONMENT
export const DIGITAL_PRODUCT_FLAG_STORAGE_NAME = 'tokoko_has_digital_product_' + ENVIRONMENT
export const WHOLESALE_TEMP_STORAGE_NAME = 'tokoko_wholesale_temp_storage_' + ENVIRONMENT
export const IS_FIRST_TIME_STOCK_EDIT = 'tokoko_first_stock_edit_' + ENVIRONMENT
export const IS_PERSIST_FILTER = 'tokoko_persist_filter_' + ENVIRONMENT

export const POSTHOG_API_KEY = 'phc_f2sSysnIwXqr6DBKDYmcvRPZYwDd4NDCPwK8oUSNp6P'
