export interface EnvironmentVariablesI {
    BUYER_AMP_KEY: string
    BUYER_BASE_URL: string
    BW_AMP_KEY: string
    GOOGLE_MAPS_KEY: string
    SELLER_AMP_KEY: string
    SELLER_BASE_URL: string
    POSTHOG_API_KEY: string
}

export const EnvironmentVariablesDefault: EnvironmentVariablesI = {
    BUYER_AMP_KEY: '',
    BUYER_BASE_URL: '',
    BW_AMP_KEY: '',
    GOOGLE_MAPS_KEY: '',
    SELLER_AMP_KEY: '',
    SELLER_BASE_URL: '',
    POSTHOG_API_KEY: ''
}
