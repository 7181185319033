import $axios from '~/lib/axios'
import locale from '~/locale'

import { API_BASE_URL, INVENTORY_FLAG_STORAGE_NAME, TOKOKO_API_BASE_URL } from '~/constants'
import {
    EnableInventoryFeatureApiResponse,
    EnableInventoryFeatureMethodResponse,
    GetInventoryApiResponse,
    GetInventoryInfoMethodResponse,
    TUpdateStockUnit
} from '~/types'
import { getErrorMessage } from '~/utils/api'

import { $log } from '~/utils/log'
import { ApiResponse } from '../accounting'

/**
 * get Inventory information for a store
 * @param storeId
 * @returns inventory active/not active and stockUpdateIn progress
 */
export const GetInventoryInfo = async (storeId: string): Promise<GetInventoryInfoMethodResponse> => {
    const request = await $axios
        .get<ApiResponse<GetInventoryApiResponse>>(`${TOKOKO_API_BASE_URL}/api/v2/store/management/inventory/details?storeId=${storeId}`)
        .then(res => {
            return {
                data: res.data.data
            }
        })
        .catch(e => {
            return getErrorMessage({}, e)
        })

    const isInventoryActive = !!(request.data as GetInventoryApiResponse).inventory ? 'active' : 'inactive'

    localStorage.setItem(INVENTORY_FLAG_STORAGE_NAME, isInventoryActive)

    return request
}

export const UpdateStockUnit = async (payload: TUpdateStockUnit): Promise<{ error: string; status: number }> => {
    const response: { error: string; status: number } = await $axios
        .patch(`${API_BASE_URL}/product/list`, payload)
        .then((res: any) => {
            if (res.status === 200) {
                return { error: res.data.responseMessages.in, status: 200 }
            }
            return { error: res.data.responseMessages.in, status: 305 }
        })
        .catch(err => {
            $log(err)
            return {
                error: locale.id.inventory.api_msg.cant_change_product,
                status: 305
            }
        })

    return response
}
/**
 * Enable Inventory
 * @param stock
 * @param userId
 */
export const EnableInventoryFeature = async (initialStock: string, userId: string): Promise<EnableInventoryFeatureMethodResponse> => {
    const request = await $axios
        .post<ApiResponse<EnableInventoryFeatureApiResponse>>(`${TOKOKO_API_BASE_URL}/api/v2/store/management/inventory/enable`, {
            initialStock,
            userId
        })
        .then(() => {
            localStorage.setItem(INVENTORY_FLAG_STORAGE_NAME, 'active')

            return {
                data: true
            }
        })
        .catch(e => {
            return getErrorMessage(false, e)
        })
    return request
}

/**
 * Disable Inventory feature for the store
 * @param userId
 */
export const DisableInventoryFeature = async (userId: string): Promise<GetInventoryInfoMethodResponse> => {
    const request = await $axios
        .put<ApiResponse<GetInventoryApiResponse>>(`${TOKOKO_API_BASE_URL}/api/v2/store/management/inventory/${userId}/disable`, {})
        .then(res => {
            localStorage.setItem(INVENTORY_FLAG_STORAGE_NAME, 'inactive')

            return {
                data: {
                    inventory: res.data.data.inventory,
                    stockUpdateInProgress: res.data.data.stockUpdateInProgress
                }
            }
        })
        .catch(e => getErrorMessage({}, e))

    return request
}
