const DesktopPrompt = () => {
    return (
        <div className="flex h-full w-full items-center justify-center overflow-auto bg-gray-100 p-5" data-testid="desktop-prompt">
            <div className="max-h-full w-full">
                <div className="mx-auto max-w-full">
                    <p className="mx-auto mb-6 text-center" style={{ maxWidth: 500 }}>
                        Untuk menggunakan Tokoko versi web (web.tokoko.id), silakan gunakan laptop atau PC.
                    </p>
                    <div className="flex justify-center">
                        <div>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.tokoko.and&hl=en&gl=US"
                                target="_blank"
                                className="relative inline-block w-full overflow-hidden rounded-md bg-blue-80 p-3 text-center text-white no-underline"
                                rel="noreferrer">
                                <span>Download versi Android</span>
                                <div className="absolute left-0 top-0 h-full w-full bg-black opacity-0 transition-opacity hover:opacity-10"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopPrompt
