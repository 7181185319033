import detail from './detail'
import timer from './timer'

export default {
    timer,
    due_date: 'Jatuh Tempo',
    weeks: 'Minggu',
    detail,
    title: 'Pesanan',
    order_source: 'Sumber pesanan',
    payment: 'pembayaran',
    delivery: 'pengiriman',
    status: 'status',
    order_date: 'Tanggal Pesanan',
    order_status: 'Status Pesanan'
}
