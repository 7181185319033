interface AuthLayoutProps {
    children: JSX.Element | JSX.Element[]
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
    return (
        <>
            <main className="flex h-full w-full overflow-auto bg-gray-100" data-testid="auth-layout">
                {children}
            </main>
        </>
    )
}

export default AuthLayout
