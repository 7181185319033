import { $error } from '~/utils/log'

import { InitialData } from '~/components/Global/GlobalProvider'
import { GetStore } from '~/data'

export const checkStore = async (nonAuthPage: boolean): Promise<InitialData> => {
    const storeFromApp = getStoreFromApp()

    if (storeFromApp) {
        if (!nonAuthPage) {
            return {
                store: storeFromApp
            }
        }
        return {
            redirect: '/',
            store: storeFromApp
        }
    }

    const { data, error } = await GetStore()

    if (error) {
        if (error.field === 'not-found') {
            return {
                redirect: '/getting-started'
            }
        }

        return {
            redirect: '/login'
        }
    }

    if (!data || data?.missmatchUid) {
        return {
            redirect: '/login'
        }
    }

    return {
        redirect: nonAuthPage ? '/' : undefined,
        store: data
    }
}

const getStoreFromApp = () => {
    try {
        if (!window.BukuWebContainer?.getStoreData) {
            return
        }

        return JSON.parse(window.BukuWebContainer.getStoreData())
    } catch (e) {
        $error('error getting store', e)
        return
    }
}
