import { API_BASE_URL, PAYMENT_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

import { APIResponse, DataResponse, GetKYCDetailsResponse, kyc, KycKybInfo, KYC_TIER } from '~/types'
import { getErrorMessage } from '~/utils/api'

interface KycKybInfoLite {
    storeId: string
    status: 'PENDING' | 'VERIFIED' | 'REJECTED'
    failureMessage?: string
    kybDetails: null
    kycTier: KYC_TIER
}

/**
 * Get onboarding details
 * @param phoneNumber
 * @returns Onboarding
 */
export const GetKycByStoreId = async (storeId: string): Promise<DataResponse<KycKybInfoLite | undefined>> => {
    const request = await $axios
        .get<KycKybInfoLite & APIResponse>(API_BASE_URL + `/kyc/${storeId}`)
        .then(res => {
            return {
                data: res.data
            }
        })
        .catch(e => {
            return getErrorMessage(undefined, e)
        })

    return request
}

export const GetKycDetails = async (phoneNumber: string): Promise<GetKYCDetailsResponse> => {
    const request: GetKYCDetailsResponse = await $axios
        .get<kyc & APIResponse>(API_BASE_URL + `/kyc/getKycInformation/${encodeURIComponent(phoneNumber)}`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return {
                    data: res.data
                }
            }

            return {
                data: {},
                error: {
                    field: 'general',
                    message: res.data.responseMessages.in
                }
            }
        })
        .catch(e => {
            return getErrorMessage<kyc>({}, e)
        })

    return request
}

export const GetKycKybDetail = async (userId: string): Promise<DataResponse<KycKybInfo | undefined>> => {
    const request = await $axios
        .get(PAYMENT_API_BASE_URL + '/accounts?partner_user_id=' + userId)
        .then(res => ({
            data: res.data
        }))
        .catch(e => {
            return getErrorMessage<KycKybInfo | undefined>(undefined, e)
        })

    return request
}
