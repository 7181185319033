export default {
    detail: {
        is_available: 'Stok tersedia',
        not_available: 'Stok habis'
    },
    bulk_upload: {
        error: {
            document_exist: 'Silakan hapus file ini terlebih dahulu untuk upload ulang file baru'
        }
    },
    product_form_stock: {
        manage_stock: 'Kelola Stok',
        product_sku: 'SKU Produk',
        auto_sku: 'SKU Otomatis',
        duplicate_sku: 'SKU telah terpakai, gunakan nomor lain',
        first_stock: 'Stok Awal',
        stock_error_message: 'Hanya memakai angka dan maksimal 99.999',
        min_3digits: 'Harap masukkan SKU min. 3 angka',
        required_fields: 'Kolom wajib diisi'
    },
    add_product: 'Tambah Produk',
    edit_product: 'Edit Produk',
    wholesale: {
        form: {
            title: 'Harga Grosir',
            blocked_description: {
                text_one: 'Fitur ini aktif ketika',
                text_two: 'Harga Normal',
                text_three: 'di input'
            },
            description: 'Biar pelanggan tahu kalau produk memiliki harga grosir, belanja banyak makin murah',
            edit: 'Ubah',
            automatic: {
                title: 'Harga Grosir Otomatis',
                description: 'Harga grosir akan otomatis berubah jika kamu mengubah harga normal.'
            },
            manage: {
                title: 'Atur Harga Grosir',
                description: 'Pilih jenis potongan harga sesuai kebutuhan tokomu'
            },
            type: {
                flat: 'Rupiah (Rp)',
                percentage: 'Persentase (%)'
            },
            field: {
                min_quantity: 'Min. Pembelian',
                price: {
                    text_one: 'Selisih Harga',
                    text_two: '/produk'
                }
            },
            add: 'Tambah Harga Grosir',
            error: {
                invalid: 'Harga dan Min. Pembelian tidak boleh 0',
                duplicate: 'Min. Pemebelian tidak boleh sama'
            },
            save: 'Simpan'
        },
        list: {
            min: 'Minimum Pembelian',
            price: 'Harga Grosir'
        }
    }
}
