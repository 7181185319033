import Cookie from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { NextRouter } from 'next/router'
import queryString from 'query-string'
import $axios from '~/lib/axios'

import {
    API_DEVICE_ID,
    AUTH_USER_ID_STORAGE_NAME,
    SELLER_COOKIE_ACCESS_TOKEN,
    SELLER_COOKIE_PN,
    SELLER_COOKIE_REFRESH_TOKEN,
    USER_SERVICE_BASE_URL
} from '~/constants'
import { AccessTokenObject, RefreshTokenRequest, RefreshTokenResponse } from '~/types/auth'

import { InitialData } from '~/components/Global/GlobalProvider'

import { UpdateStoreUserId } from '~/data'
import { requestInterceptor } from '~/lib/axiosInterceptor'
import $zohoSalesIq from '~/lib/zoho'
import { $error, $log } from './log'
import { checkStore } from './store'

/**
 * Get refresh token
 * @param phone
 * @param isFromResInterceptor
 * @returns
 */
export const refreshToken = async (isFromResInterceptor = false) => {
    const data = await $axios
        .post<RefreshTokenResponse>(USER_SERVICE_BASE_URL + '/users/bacon', {
            deviceBrand: 'Iphone',
            deviceId: API_DEVICE_ID,
            deviceModel: '13',
            register: false
        } as RefreshTokenRequest)
        .then(async res => {
            return {
                success: true,
                isTokenRefresh: isFromResInterceptor,
                xsrfToken: res.data.xsrfToken
            }
        })
        .catch(() => {
            return {
                success: false,
                isTokenRefresh: false,
                xsrfToken: undefined
            }
        })

    return data
}

/**
 * Get authentication related cookie
 * will return [access token cookie, phone number cookie]
 * @param param
 * @returns
 */
export const getAuthCookie = async () => {
    const query = queryString.parse(window.location.search)

    if (query.platform === 'android') {
        const accessToken = Cookie.get(SELLER_COOKIE_ACCESS_TOKEN)
        const refreshToken = Cookie.get(SELLER_COOKIE_REFRESH_TOKEN)
        const phone = Cookie.get(SELLER_COOKIE_PN)

        if (accessToken && refreshToken && phone) {
            await $axios
                .post(USER_SERVICE_BASE_URL + '/set-auth', {
                    idToken: accessToken,
                    refreshToken,
                    phone
                })
                .then(res => {
                    requestInterceptor(res.data.xsrfToken)
                    removeCookie()
                })
                .catch(e => $error(e))
        }

        $log('login from app', accessToken, refreshToken, phone)
        return
    }

    if (query.refresh_token && query.user_token && query.phone) {
        const accessToken = query.user_token
        const refreshToken = query.refresh_token
        const phone = window.decodeURIComponent(query.phone as string)

        if (accessToken && refreshToken && phone) {
            await $axios
                .post(USER_SERVICE_BASE_URL + '/set-auth', {
                    idToken: accessToken,
                    refreshToken,
                    phone
                })
                .then(res => {
                    requestInterceptor(res.data.xsrfToken)
                })
                .catch(e => $error(e))
        }

        $log('login from app', accessToken, refreshToken, phone)
    }
}

/**
 * Will check authentication by json web token
 * @param param
 * @returns
 */
export const authenticate = async (router: NextRouter): Promise<InitialData> => {
    const nonAuthPage = ['/login', '/_error'].includes(router.pathname)
    await getAuthCookie()

    const data = await $axios
        .post<{ result: boolean; idToken: string }>(USER_SERVICE_BASE_URL + '/check-auth')
        .then(res => {
            const { auth_user_id }: AccessTokenObject = jwtDecode(res.data.idToken)
            localStorage.setItem(AUTH_USER_ID_STORAGE_NAME, auth_user_id)
            return res.data.result
        })
        .catch(() => {
            return false
        })

    if (!data) {
        if (nonAuthPage) {
            return {}
        }

        $error('Not authenticated, redirect to login')
        // current page is authorized page
        // so redirect user to non authorized page

        return {
            redirect: '/login'
        }
    }

    // get user store data
    return await checkStore(nonAuthPage)
}

export const mapUserId = async (token: string, router: NextRouter, onSuccess: () => void, onError: () => void) => {
    const tokenObject: AccessTokenObject = jwtDecode(token)
    const data: InitialData = await authenticate(router)
    const storeId = data.store?.storeId
    const user_id = data.store?.userId

    if (storeId && user_id != tokenObject.auth_user_id) {
        const { status } = await UpdateStoreUserId(storeId, tokenObject.auth_user_id)

        if (status === 'success') {
            onSuccess()
            return
        }

        onError()
        return
    }

    window.location.href = '/'
}

export const getUserId = () => {
    // Get user is from storage
    const storageUserId = localStorage.getItem(AUTH_USER_ID_STORAGE_NAME)

    // check if user id is stored
    if (!!storageUserId && storageUserId !== '') {
        $log('user id is stored in storage')

        return storageUserId
    }

    // return null for this anonymous user
    $log('return null as user id')
    return null
}

export const logout = async () => {
    await $axios.post(USER_SERVICE_BASE_URL + '/logout').catch(() => {})
    removeCookie()
    localStorage.removeItem(AUTH_USER_ID_STORAGE_NAME)
    $zohoSalesIq.resetVisitor()
}

const removeCookie = () => {
    Cookie.remove(SELLER_COOKIE_ACCESS_TOKEN!)
    Cookie.remove(SELLER_COOKIE_REFRESH_TOKEN!)
    Cookie.remove(SELLER_COOKIE_PN!)
}
