import { IconsProps } from '~/types'

const IconCheckRound = ({ className }: IconsProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" className={className} fill="none">
            <circle cx="9" cy="9" r="7.5" fill="#03AD87" />
            <path d="M12.75 6.75L7.59375 11.9062L5.25 9.5625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconCheckRound
