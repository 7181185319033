import { TOKOKO_API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'
import locale from '~/locale'

import { APIDataResponse, DataResponse } from '~/types'
import { StoreDetail } from '~/types/store'
import { getErrorMessage } from '~/utils/api'
import { $error } from '~/utils/log'

/**
 * Check if store is BNPL store
 * @param storeLink
 * @returns
 */
export const IsStoreBnpl = async (storeLink: string | undefined): Promise<DataResponse<boolean>> => {
    const response = await $axios
        .get<APIDataResponse<boolean>>(`${TOKOKO_API_BASE_URL}/api/v2/store/management/${storeLink}/isbnpl`)
        .then(res => {
            return { data: res.data.data }
        })
        .catch(e => {
            return getErrorMessage<boolean>(false, e)
        })
    return response
}

/**
 * Check store link availability
 * @param storeLink
 * @returns
 */
export const StoreLinkAvailability = async (storeLink: string) => {
    const request: { available?: boolean; error?: any } = await $axios
        .get<{ data: { link: string; available: boolean }; responseMessages: any }>(
            TOKOKO_API_BASE_URL + '/api/v2/store/management/link/available?link=' + storeLink
        )
        .then(res => {
            if (res.status === 200 && res.data.data.available) {
                return { available: true }
            }

            return {
                available: false,
                error: {
                    field: 'general',
                    message: 'Link toko tidak tersedia'
                }
            }
        })
        .catch(err => {
            return getErrorMessage({}, err)
        })

    return request
}

/**
 * Create Store
 * @param store
 * @returns
 */
export const CreateStore = async (store: {
    businessCategory: string[]
    storeLink: string
    storeName: string
    lat: number
    long: number
    banks: string[]
    storeViews: number
    productViews: number
    storeRevenue: number
    deliverySetUpStatus: boolean
    inventory: boolean
    stockUpdateInProgress: boolean
}) => {
    const request: { storeId?: string; error?: any } = await $axios
        .post(TOKOKO_API_BASE_URL + '/api/v2/store/management', store)
        .then(res => {
            return { storeId: res.data.data.storeId }
        })
        .catch(err => {
            return getErrorMessage({}, err)
        })

    return request
}

/**
 * Update store
 * @param store
 * @returns
 */
export const UpdateStore = async (store: Partial<StoreDetail>): Promise<DataResponse<boolean>> => {
    const request: DataResponse<boolean> = await $axios
        .put<APIDataResponse>(TOKOKO_API_BASE_URL + '/api/v2/store/management', store)
        .then(res => ({
            data: res.data.result
        }))
        .catch(err => {
            return getErrorMessage(false, err)
        })

    return request
}

export const GetStore = async (): Promise<DataResponse<StoreDetail | undefined>> => {
    const request = await $axios
        .get<APIDataResponse<StoreDetail>>(TOKOKO_API_BASE_URL + '/api/v2/store/management')
        .then(res => {
            return {
                data: res.data.data
            }
        })
        .catch(e => {
            if (e?.response?.status === 404 || e?.response?.status === 400 || e?.response?.status === 500) {
                return {
                    data: undefined,
                    error: {
                        field: 'not-found',
                        message: 'Store is not found'
                    }
                }
            }

            return {
                data: undefined,
                error: {
                    field: 'general',
                    message: locale.id.common.request_error
                }
            }
        })

    return request
}

/**
 * Update store user id
 * @param storeId
 * @param userId
 * @returns
 */
export const UpdateStoreUserId = (storeId: string, userId: string) => {
    return $axios
        .post(`${TOKOKO_API_BASE_URL}/api/v2/store/management/${storeId}/users?userId=${userId}`)
        .then(() => {
            return { status: 'success' }
        })
        .catch(err => {
            $error('Update user id error', err)
            return {
                status: 'error'
            }
        })
}

export const StoreDetailDefaultValue: StoreDetail = {
    addrArea: '',
    addrAreaId: '',
    addrCity: '',
    addrCityId: '',
    addrLatitude: 0,
    addrLongitude: 0,
    addrName: '',
    addrPhone: '',
    addrProvince: '',
    addrProvinceId: '',
    addrStreet: '',
    addrSuburb: '',
    addrSuburbId: '',
    address: '',
    addressFirstLine: '',
    banks: [],
    businessCategory: [],
    categoryIdsIndexed: [],
    city: '',
    createdAt: 0,
    createdByDevice: '',
    createdByUser: '',
    deliveryRateIds: [],
    deliverySetUpStatus: false,
    deliveryTypes: [],
    deviceName: '',
    flagged: '',
    fullName: '',
    lat: 0,
    newStoreLink: '',
    paymentAccountId: '',
    phone: '',
    pinLock: '',
    productViews: 0,
    province: '',
    selfDeliverySettings: {
        amount: '',
        deliveryArea: '',
        enabled: false,
        estimatedTimeInHours: 0
    },
    storeId: '',
    storeLink: '',
    storeLogo: '',
    storeName: '',
    storeRevenue: 0,
    storeTimes: [],
    storeType: '',
    storeViews: 0,
    subDistrict: '',
    updatedAt: 0,
    updatedByDevice: '',
    updatedByUser: '',
    wallets: [],
    appVersionCode: 0,
    totalRatingsCount: 0,
    totalRatingsValue: 0,
    verified: false,
    kycVerified: false,
    onboardingId: '',
    isBNPL: false,
    inventory: false,
    userId: ''
}
