import { responsiveBreakpointMap, Sidebar, SidebarListItem, SidebarListSubitem, TokokoLogo } from '@bukuwarung/sachet'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'

import navMenu from '~/data/layout/navMenu'
import { sendAmplitudeEvent } from '~/utils/amplitude'

import { useGlobal } from '~/components/Global/GlobalProvider'

import QueryString from 'query-string'
import { OnboardingPopup } from '~/components/Onboarding/OnboardingPopup'
import { OnboardingPrompt } from '~/components/Onboarding/OnboardingPrompt'
import { routerTo } from '~/utils/page'
import DesktopPrompt from '../DesktopPrompt'

interface DashboardProps {
    children: JSX.Element | JSX.Element[]
    hideNavigation?: boolean
    mobile?: boolean
    showNavbar?: boolean
    pathname?: string
    shrink?: boolean
    hideOnboarding?: boolean
    isAndroidWebview?: boolean
}

const Dashboard = ({ children, hideNavigation, mobile = false, pathname = '', hideOnboarding, isAndroidWebview }: DashboardProps) => {
    const { store, onboardChecks, showOnlineStore, localStore } = useGlobal()
    const [showOnboard, setShowOnboard] = useState(false)
    const [showOnboardPrompt, setShowOnboardPrompt] = useState(false)

    const router = useRouter()
    const parentRoute = '/' + pathname.split('/')[1]

    const getEventHandler = (slug: string) => {
        switch (slug) {
            case '/':
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_home_clicked')
                }
            case routerTo.order.url():
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_orders_clicked')
                }
            case routerTo.invoice.url():
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_invoice_clicked')
                }
            case routerTo.customer.url():
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_customers_clicked')
                }
            case routerTo.supplier.url():
                return () => {
                    // sendAmplitudeEvent('homescreen:tab_bar_vendors_clicked')
                }
            case routerTo.product.url():
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_products_clicked')
                }
            case routerTo.account.url():
                return () => {
                    sendAmplitudeEvent('homescreen:tab_bar_account_clicked')
                }
            default:
                return
        }
    }

    const mapNavItems = () => {
        const navMenuWithIcons = navMenu.map(item => {
            return {
                ...item,
                event: getEventHandler(item.slug)
            }
        })

        return navMenuWithIcons
    }

    const getOnboardProgress = () => {
        let countChecks = 0

        for (const key in onboardChecks) {
            const checks = onboardChecks[key as keyof typeof onboardChecks]
            if (checks) countChecks++
        }

        return countChecks
    }

    const onboardProgress = getOnboardProgress()

    const [listItem] = useState(() => mapNavItems())

    useEffect(() => {
        checkOnboardQuery()
    }, [])

    const checkOnboardQuery = () => {
        if (localStore?.get<boolean>('ONBOARDING_CLOSED')?.data || isAndroidWebview) {
            setShowOnboardPrompt(false)
            return
        }

        setShowOnboardPrompt(true)

        const query = QueryString.parse(window.location.search)

        if (!!query.onboard && onboardProgress < 4) {
            router.replace('/', undefined, { shallow: true })
            setShowOnboard(true)
        }
    }

    if (!mobile && window.innerWidth < responsiveBreakpointMap.md) {
        return (
            <>
                <DesktopPrompt></DesktopPrompt>
            </>
        )
    }

    return (
        <main className="flex h-full w-full flex-col bg-neutrals-100" data-testid="dashboard">
            <div className="relative flex flex-grow flex-col-reverse overflow-hidden sm:flex-row">
                {!hideNavigation && (
                    <Sidebar
                        prepend={
                            <>
                                <div className="hidden justify-center border-b border-neutrals-200 px-4 pb-4 pt-4 sm:flex">
                                    <TokokoLogo
                                        onClick={() => {
                                            router.push('/')
                                        }}></TokokoLogo>
                                </div>
                                {showOnboardPrompt && (
                                    <div className="hidden sm:block">
                                        <OnboardingPrompt
                                            onboardProgress={onboardProgress}
                                            setShowOnboard={setShowOnboard}
                                            setShowOnboardPrompt={setShowOnboardPrompt}></OnboardingPrompt>
                                    </div>
                                )}
                            </>
                        }>
                        {listItem.map((item, key) => (
                            <SidebarListItem
                                active={item.slug === parentRoute}
                                key={key}
                                icon={item.icon}
                                onClick={() => {
                                    router.push(item.submenu ? item.submenu[0].slug : item.slug)

                                    if (item.event) {
                                        item.event()
                                    }
                                }}
                                showInSubitem={false}
                                subitem={
                                    item.submenu &&
                                    item.submenu.map(submenu =>
                                        !showOnlineStore && submenu.slug === routerTo.order.url() ? (
                                            <Fragment key={submenu.slug}></Fragment>
                                        ) : (
                                            <SidebarListSubitem
                                                key={submenu.slug}
                                                active={router.pathname === submenu.slug}
                                                onClick={() =>
                                                    router.push(
                                                        submenu.link ? submenu.link(store?.userId || '', store?.phone || '') : submenu.slug
                                                    )
                                                }>
                                                {submenu.text}
                                            </SidebarListSubitem>
                                        )
                                    )
                                }>
                                {item.text}
                            </SidebarListItem>
                        ))}
                    </Sidebar>
                )}
                <div className="relative flex flex-grow flex-col overflow-hidden bg-neutrals-light">
                    {!hideOnboarding && showOnboardPrompt && (
                        <div className="sm:hidden">
                            <OnboardingPrompt
                                onboardProgress={onboardProgress}
                                setShowOnboard={setShowOnboard}
                                setShowOnboardPrompt={setShowOnboardPrompt}></OnboardingPrompt>
                        </div>
                    )}
                    {children}
                </div>
            </div>
            <OnboardingPopup show={showOnboard} setShow={setShowOnboard} onboardProgress={onboardProgress}></OnboardingPopup>
        </main>
    )
}

export default Dashboard
