import moment from 'moment'

import { $log } from '~/utils/log'

import { responsiveBreakpointMap } from '@bukuwarung/sachet'
import { countryISDSVG } from '~/components/Icons/IconCountry'

export const getAddress = (store: any, isStreetInclude: boolean) => {
    const _address = []
    if ((store?.addrStreet || store?.addressStreet) && isStreetInclude) {
        _address.push(store.addrStreet || store.addressStreet)
    }

    if (store?.addrSuburb || store?.addressSuburb) {
        _address.push(store.addrSuburb || store?.addressSuburb)
    }

    if (store?.addrCity || store?.addressCity) {
        _address.push(store.addrCity || store?.addressCity)
    }

    if (store?.addrProvince || store?.addressProvince) {
        _address.push(store.addrProvince || store?.addressProvince)
    }

    if (store?.addrArea || store?.addressArea) {
        _address.push(store.addrArea || store?.addressArea)
    }

    const addressText = _address.join(', ')

    if (addressText.length) {
        return addressText
    }

    return ''
}

export const delayedCallback = (callback: () => void, timeout = 250) => {
    setTimeout(() => {
        callback()
    }, timeout)
}

export const closeWebView = () => {
    $log('call closeWebView')
    const container = window?.BukuWebContainer

    $log('container', container)

    if (!container) {
        return
    }

    $log('closeWebView', container.closeWebview)
    if (!container.closeWebview) {
        return
    }

    container.closeWebview()
}

export const setOnBackPressed = (callback: () => void) => {
    $log('call setOnBackPressed')
    const container = window.BukuWebContainer

    $log('container', container)
    if (!container) {
        return
    }

    $log('set on back pressed event')
    container.onBackPressed = callback
}

export const SkuIDGenerator = () => {
    const random = Math.floor(1000000000 + Math.random() * 9000000000).toString()
    return '123' + random
}

export const IsNumberInput = (data: string) => {
    const regex = /^[0-9\b]+$/
    if (regex.test(data) || data.length === 0) {
        return true
    }
    return false
}

export const dueDatePassedDiff = (dueDate = '') => moment().diff(moment(dueDate), 'days')

export const convertToNumber = (value: string) => {
    return Number(value.replace(/\D/g, '').replace(/\b0+/g, ''))
}

export const getPercentageOf = (variable: number, base: number) => {
    return (variable / base) * 100
}

export const getFromPercentage = (variable: number, base: number) => {
    return (variable / 100) * base
}

export const countryListOptions = countryISDSVG.map(item => {
    return {
        icon: item.svg,
        longText: `(${item.code}) ${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`,
        text: `${item.code}`,
        value: `${item.code}`
    }
})

export const countryCodeFieldWidth = (deviceWidth?: number) => {
    return !deviceWidth ? 119 : deviceWidth <= responsiveBreakpointMap.sm ? 119 : 150
}

export const saveButtonSize = (deviceWidth?: number) => {
    return !deviceWidth ? 'xs' : deviceWidth <= responsiveBreakpointMap.sm ? 'xs' : 'sm'
}

/**
 * Get percentage between two number
 * @param {number} a - Number a
 * @param {number} b - Number b
 * @returns {number} - Percentage
 */
export const getPercentageBetweenTwo = (a: number, b: number) => (a / b) * 100

/**
 * Get percentage change between two number
 * @param {number} a - Number a
 * @param {number} b - Number b
 */
export const getPercentageDifference = (a: number, b: number) => {
    if (b === 0) {
        if (a === 0) {
            return 0
        }
        return 100
    }
    const diff = a - b
    const percentage = (diff / b) * 100
    return percentage
}

/**
 * Get discount percentage
 * @param {number} price - Price
 * @param {number} discount - Discount
 * @returns {number} - Discount percentage
 */
export const getDiscountPercentage = (price: number, discount: number) => {
    const discountPercentage = ((price - discount) / price) * 100
    return discountPercentage
}

/**
 * Format percentage
 * @param {number} percentage - Percentage
 * @returns {number} - Formatted percentage
 */
export const formatPercentage = (percentage: number) => {
    return Number(percentage.toFixed(2))
}

export const regexSupportedPercentageFormat = /^(?:\d|[1-9]\d|100)(?:\.\d{0,2})?$/
export const regexSupportedBigIntFormat = /^(?:\d|[1-9]\d{1,4}|)(?:\.\d{0,2})?$/
/**
 * Check if percentage number has more than two digit after decimal
 * @param {number} value - Number
 * @returns {boolean} - True if percentage number has more than two digit after decimal
 */
export const isNotSupportedPercentageFormat = (value: number) => {
    const stringNumber = String(value)
    return !!stringNumber.match(/^(?:\d|[1-9]\d|100)(?:\.\d{3,})$/)
}

export const groupCheck: <T, A extends T>(value: T, arr: A[]) => boolean = (value, arr) => {
    return (arr as (typeof value)[]).includes(value)
}

export const splitPhoneNumber = (phoneNumber: string) => {
    const regex = /^(\+(62|91|60|66|673|84|855|63|95|856|65|670))(\d+)$/

    const matches = phoneNumber.match(regex)

    if (matches) {
        const countryCode = matches[1]
        const actualNumber = matches[3]

        return {
            countryCode: countryCode,
            phoneNumber: actualNumber
        }
    }

    return null
}
