import { API_BASE_URL } from '~/constants'
import $axios from '~/lib/axios'

export const getHeading = (step: number) => {
    let headingData
    switch (step) {
        case 1:
            headingData = {
                heading: 'Informasi Data Diri',
                subHeading: 'Masukkan data identitas kamu di sini'
            }
            break
        case 2:
            headingData = {
                heading: 'Alamat Toko',
                subHeading: 'Masukkan alamat tempat usahamu saat ini.'
            }
            break
        case 3:
            headingData = {
                heading: 'Dokumen Persetujuan',
                subHeading: 'Pastikan membaca dokumen Talangin Dulu yang tertulis dari pihak Tokoko untuk bukti persetujuanmu ya'
            }
            break
        case 4:
            headingData = {
                heading: 'Foto Tanda Tangan Kamu',
                subHeading: 'Pastikan tanda tangan kamu sesuai dengan di KTP'
            }
            break
        case 5:
            headingData = {
                heading: 'Konfirmasi Foto Tanda Tangan',
                subHeading: 'Pastikan tanda tangan kamu sesuai dengan di KTP'
            }
            break
        default:
            headingData = {
                heading: 'Informasi Data Diri',
                subHeading: 'Masukkan data identitas kamu di sini'
            }
    }

    return headingData
}

export const getBnplProgressData = async (onBoardingId: string) => {
    const data = await $axios
        .get(API_BASE_URL + `/onboarding/${onBoardingId}/step`)
        .then(res => {
            if (res.data.responseType === 'SUCCESS') {
                return res.data.onboarding_step
            }
            return {}
        })
        .catch(() => {
            return {}
        })
    return data
}

export * from './addressBank'
export * from './confirm'
export * from './details'
export * from './identity'
export * from './image'
export * from './kyc'
export * from './status'
